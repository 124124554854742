import { Skeleton } from "primereact/skeleton";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { TransactionStatus } from "src/components/constants/transaction-status";
import { ROUTERS_PATH } from "src/routers/routers";
import { TransactionService } from "src/services/transaction/transaction.service";
import useUserStore from 'src/services/user/userstore';

export default function AuthApproverPage() {
  const { session } = useParams();
  const navigate = useNavigate();
  const loggedInUser = useUserStore((state) => state.user);
  const setUser = useUserStore((state) => state.setUser);  

  useEffect(() => {
    try {      
      if (loggedInUser) {setUser(loggedInUser)};
      handleNavigation(true);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const handleGetTransactionDetail = async () => {
    if (!session) return;
    try {
      const transactionService: TransactionService = new TransactionService();
      const response = await transactionService.getTransByToken(session || "");
      if (response) {
        const { isUserEnrolled } = response;
        handleNavigation(isUserEnrolled ?? false);
      }
    } catch (error) {
      console.error(error);
      navigate(ROUTERS_PATH.SIGN_IN);
    }
  };

  const handleNavigation = (isEnroll: boolean) => {    
      navigate(`${ROUTERS_PATH.APPROVE_REQUEST}/${session}`);    
  };

  return (
    <>
      <div className="border-round border-1 surface-border p-4 surface-card">
        <div className="flex mb-3">
          <Skeleton shape="circle" size="4rem" className="mr-2"></Skeleton>
          <div>
            <Skeleton width="10rem" className="mb-2"></Skeleton>
            <Skeleton width="5rem" className="mb-2"></Skeleton>
            <Skeleton height=".5rem"></Skeleton>
          </div>
        </div>
        <Skeleton width="100%" height="150px"></Skeleton>
        <div className="flex justify-content-between mt-3">
          <Skeleton width="4rem" height="2rem"></Skeleton>
          <Skeleton width="4rem" height="2rem"></Skeleton>
        </div>
        <Skeleton className="mb-2"></Skeleton>
        <Skeleton width="10rem" className="mb-2"></Skeleton>
        <Skeleton width="5rem" className="mb-2"></Skeleton>
        <Skeleton height="2rem" className="mb-2"></Skeleton>
        <Skeleton width="10rem" height="4rem"></Skeleton>
        <div>
          <Skeleton width="10rem" className="mb-2"></Skeleton>
          <Skeleton width="5rem" className="mb-2"></Skeleton>
          <Skeleton height=".5rem"></Skeleton>
        </div>
        <Skeleton width="100%" height="150px"></Skeleton>
        <Skeleton className="mb-2"></Skeleton>
        <Skeleton width="10rem" className="mb-2"></Skeleton>
        <Skeleton width="5rem" className="mb-2"></Skeleton>
        <Skeleton height="2rem" className="mb-2"></Skeleton>
      </div>
    </>
  );
}
