import React from "react";
import { InputType } from "./type";
import "./input.scss";
import CurrencyInput from 'react-currency-input-field';
import { FormikContextType, useFormikContext } from "formik";

export default function Index(props: InputType) {
  const {
    type,
    id,
    label,
    placeholder,
    border,
    className,
    name,
  } = props;

  const formikContext: FormikContextType<any> = useFormikContext();

  let tempPlaceholder: any = placeholder;
  let errorClass = "";

  const { errors, touched, handleChange, handleBlur, setValues } =
    formikContext;

  if (errors[name] && touched[name]) {
    tempPlaceholder = errors[name];
    errorClass = "error-field";
  }


  const handleOnChange = (e: any) => {
      handleChange(e);
  };

  const handleOnBlur = (e: any) => {
    if (errors[name] && touched[name]) {
      setValues({...formikContext.values, [name]: ""});
      handleBlur(e);
    } else {
      handleBlur(e);
    }
  };

  const handleCopyPasteBehavior = (e: any) => {
    if (name == "amount") {
      e.preventDefault();
    }
    return e;
  }

  return (
    <div
      className={`default-input ${type} ${className ?? ""} 
      ${border ? " border border-[#05ACB180]" : ""} ${errorClass ?? ""}
      `}
    >
      <span className={`label text-[#494949] ${className ?? ""}`}>{label}</span>
      <div className="flex items-center gap-1">
        {type == "currency" ? (        
        <CurrencyInput
          id={id}
          name={name}
          placeholder={tempPlaceholder}
          className={`w-full outline-none !bg-transparent ${errorClass}`}
          value={formikContext.values[name]}
          onValueChange={(value) => formikContext.setFieldValue(name, value)}          
          onBlur={handleOnBlur}
          onPaste={handleCopyPasteBehavior}
          onCopy={handleCopyPasteBehavior}
          autoComplete={name == "amount"? "off":"on"}          
          prefix="$"
          decimalScale={2}    
          maxLength={10}      
        />):
        (<input
          id={id}
          name={name}
          type={type}
          className={`w-full outline-none !bg-transparent ${errorClass}`}
          placeholder={tempPlaceholder}
          value={formikContext.values[name]}
          onChange={handleOnChange}
          onBlur={handleOnBlur}
          onPaste={handleCopyPasteBehavior}
          onCopy={handleCopyPasteBehavior}
          autoComplete={name == "amount"? "off":"on"}
        />)}
      </div>
    </div>
  );
}
