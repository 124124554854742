export const CopyToClipBoard = (textToCopy: string) => {
  navigator.clipboard
    .writeText(textToCopy)
    .then(() => {
      console.log("Copied!");
    })
    .catch((err) => {
      console.log("Failed to copy!");
    });
};
