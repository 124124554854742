import moment from "moment";

export enum EnumDateFormat {
  DEFAULT_FORMAT = "MMMM DD YYYY, HH:mm",
}

export const FormatingDate = (
  isoDateString: string,
  formatDate: EnumDateFormat = EnumDateFormat.DEFAULT_FORMAT
) => {
  const localDate = moment(isoDateString).local();
  return localDate.format(formatDate);
};
