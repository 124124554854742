import React from "react"
import { Sheet } from "react-modal-sheet"
import closeIcon from "src/assets/close-icon.svg"
import { ModalType } from "./type"


export default function Modal(props: ModalType) {
	const {isOpen, closeModal, children} = props
  return (
	<Sheet disableDrag={true} isOpen={isOpen} onClose={closeModal} detent="content-height" className="max-w-[390px] m-auto !z-[100]">
		<Sheet.Container>
		<Sheet.Content>
			<div className="content-modal flex flex-col min-h-fit p-4">
			<div className="flex justify-end">
				<img className="w-[24px] h-[24px]" onClick={closeModal} src={closeIcon} alt="closeIconModal"/>
			</div>
			<div className="flex-1">
				{children}
			</div>
			</div>
		</Sheet.Content>
		</Sheet.Container>
		<Sheet.Backdrop />
	</Sheet>
  )
}

