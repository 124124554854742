import { API } from "../api-constants";
import { HttpClient } from "../common-services/httpclient.service";

export class SMSService extends HttpClient {
  private readonly servivePath = API.SMS;
  constructor() {
    super();
  }

  public async resend(id: number) {
    return await this.post(
      this.servivePath.SEND,
      {},
      { params: { transactionId: id } }
    );
  }
}
