// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.camera-instruction {
    width: 100%
}
.camera-instruction * {
    margin: auto;
    margin-top: 0.5rem;
    width: -moz-fit-content;
    width: fit-content
}`, "",{"version":3,"sources":["webpack://./src/components/shares/camera-instruction/camera-instruction.scss"],"names":[],"mappings":"AACI;IAAA;AAAA;AAEI;IAAA,YAAA;IAAA,kBAAA;IAAA,uBAAA;IAAA;AAAA","sourcesContent":[".camera-instruction {\n    @apply w-full;\n    * {\n        @apply w-fit m-auto mt-2;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
