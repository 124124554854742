import axios, {
  AxiosInstance,
  AxiosResponse,
  AxiosError,
  AxiosRequestConfig,
  InternalAxiosRequestConfig,
} from "axios";
import { useNavigate } from "react-router-dom";
import { ApiResponse } from "src/utils";
import axiosRetry from "axios-retry";

interface ApiService {
  get<T>(url: string): Promise<any>;
  post<T>(url: string, data: any): Promise<any>;
  put<T>(url: string, data: any): Promise<any>;
  patch<T>(url: string, data: any): Promise<any>;
  delete<T>(url: string): Promise<any>;
}

class HttpClient implements ApiService {
  private readonly instance: AxiosInstance;

  constructor() {
    const baseURL = process.env.REACT_APP_SERVER_BASE_URL;
    this.instance = axios.create({
      baseURL,
      headers: {
        "Content-Type": "application/json",
      },
    });

    this.instance.interceptors.response.use(
      this.handleSuccess,
      this.handleError
    );

    this.instance.interceptors.request.use(
      this.handleRequestConfig,
      this.handleRequestError
    );
  }

  private handleSuccess<T>(response: AxiosResponse<T>): T {
    return response.data;
  }

  private handleError(error: any): never {
    console.error("Request error:", error);
    if (axios.isAxiosError(error)) {
      const e = error as AxiosError;
      const statusCode = (e.response?.data as ApiResponse).code;
      switch (statusCode) {
        case 401:
          window.location.replace("/signin");
          break;
        default:
          break;
      }
    }
    throw error;
  }

  private handleRequestConfig<T>(
    config: InternalAxiosRequestConfig<any>
  ): InternalAxiosRequestConfig<any> {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  }

  private handleRequestError(error: any): Promise<any> {
    console.error("Request error:", error);
    return Promise.reject(error);
  }

  public async get<T>(
    url: string,
    config?: AxiosRequestConfig<any>
  ): Promise<any> {
    try {
      const response = await this.instance.get<T>(url, config);
      return response;
    } catch (error: any) {
      this.handleError(error);
    }
  }

  public async post<T>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig<any>
  ): Promise<any> {
    try {
      const response = await this.instance.post<T>(url, data, config);
      return response;
    } catch (error: any) {
      this.handleError(error);
    }
  }

  public async put<T>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig<any>
  ): Promise<any> {
    try {
      const response = await this.instance.put<T>(url, data, config);
      return response;
    } catch (error: any) {
      this.handleError(error);
    }
  }

  public async patch<T>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig<any>
  ): Promise<any> {
    try {
      const response = await this.instance.patch<T>(url, data, config);
      return response;
    } catch (error: any) {
      this.handleError(error);
    }
  }

  public async delete<T>(url: string): Promise<any> {
    try {
      const response = await this.instance.delete<T>(url);
      return response;
    } catch (error: any) {
      this.handleError(error);
    }
  }

  public async postTry<T>(
    url: string,
    retry: { times: number; timeout: number } = { times: 1, timeout: 1000 },
    data?: any,
    config?: AxiosRequestConfig<any>
  ): Promise<any> {
    try {
      axiosRetry(this.instance, {
        retries: retry.times, // Number of retries
        retryDelay: (retryCount) => {
          console.log(`Retry attempt: ${retryCount}`);
          return retryCount * retry.timeout; // Delay between retries (in milliseconds)
        },
        retryCondition: (error) => {
          const status = error.response?.status ?? 200;
          return (
            axiosRetry.isNetworkOrIdempotentRequestError(error) ||
            (status >= 400 && status <= 599)
          );
        },
      });
      const response = await this.instance.post<T>(url, data, config);
      return response;
    } catch (error: any) {
      this.handleError(error);
    } finally {
    }
  }
}

export { HttpClient };
