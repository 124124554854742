import React, { useEffect, useState } from "react";
import "./dashboard.scss";
import { TransactionItem } from "./components";
import { transactions, notifications } from "./mock-data";
import { Link } from "react-router-dom";
import { BasicButton, Modal } from "src/components/shares";
import { TransactionService } from "src/services/transaction/transaction.service";
import { TransListModel } from "src/services/transaction/type";
import { ApiResponse, FormatingDate } from "src/utils";
import { TransactionStatus } from "src/components/constants/transaction-status";
import { NotificationListModel } from "src/services/notification/type";
import { NotificationService } from "src/services/notification/notification.service";
import { SMSService } from "src/services/sms/sms.service";
import { ROUTERS_PATH } from "src/routers/routers";
import { useNavigate } from "react-router-dom";
import axios, { AxiosError } from "axios";
import { Skeleton } from "primereact/skeleton";
import { CopyToClipBoard } from "src/utils/copy";
import { DetectDevice } from "src/utils/detection";
import useUserStore from 'src/services/user/userstore';
import { useLayoutContext } from 'src/components/layout';


export default function Index() {
  const navigation = useNavigate();
  const [isOpen, setOpen] = useState(false);
  const [notiList, setNotiList] = useState<any>([]);
  const [currentNotification, setCurrentNotification] = useState<any>(
    notifications[0]
  );
  const [transactionList, setTransactionList] = useState<any[]>([]);
  const [itemChanges, setItemChanges] = useState<boolean>(false);
  const [isOpenError, setOpenError] = useState(false);
  const [error, setError] = useState<string>("");
  const [isApprover, setIsApprover] = useState<boolean>(false);
  const [link, setLink] = useState<string>("");      
  const loggedInUser = useUserStore((state) => state.user);
  const setUser = useUserStore((state) => state.setUser);  
  const clearUser = useUserStore((state) => state.clearUser);  
  const { ShowHideLogOut } = useLayoutContext();


  useEffect(() => {    
    ShowHideLogOut();          
     const isApprover = localStorage.getItem("isApprover") == "true" ? true : false;    
    setIsApprover(isApprover);
    handleGetNotifications();    
  }, []);

  useEffect(() => {
    if (!isOpen && notiList.length > 0) {
      setCurrentNotification(notiList.shift());
      setNotiList(notiList);
      setOpen(true);
    }
    handleGetTransactions();
  }, [isOpen]);

  useEffect(() => {
    handleGetTransactions();
  }, [itemChanges]);

  const handleCloseModal = (id: number) => {
    handleNotiOnMarkRead(id);
    setOpen(false);
  };

  const handleGetTransactions = async () => {
    try {
      const requestParams: TransListModel = {
        limit: 999,
        page: 1,
      };
      const transactionService: TransactionService = new TransactionService();
      const response = await transactionService.getTrans(requestParams);
      if (response) {
        const { data } = response;
        const transactionList = data.map((trans: any) => {
          const {
            id,
            transactionStatus,
            amount,
            care,
            payee,
            note,
            updatedAt,
            stateStatus,
          } = trans;
          return {
            id: id,
            session: String(id),
            status:
              stateStatus.name == TransactionStatus.DONE
                ? "Done"
                : transactionStatus.name ?? "unknown",
            statusDisplay:
              stateStatus.name == TransactionStatus.DONE
                ? "Done"
                : transactionStatus.description ?? "unknown",
            amount: amount,
            to: payee.name,
            service: note.description,
            dateTime: FormatingDate(updatedAt),
            approver: `${care.firstName} ${care.lastName}`,
          };
        });
        setTransactionList(transactionList);
      }
    } catch (error) {
      console.error(error);
      if (axios.isAxiosError(error)) {
        const e = error as AxiosError;
        setError((e.response?.data as ApiResponse).message || "");
        setOpenError(true);
      }
    }
  };

  const handleGetNotifications = async () => {
    try {
      const requestParams: NotificationListModel = {
        limit: 999,
        page: 1,
      };
      const notificationService: NotificationService =
        new NotificationService();
      const response = await notificationService.getNotifications(
        requestParams
      );
      if (response) {
        const { data } = response;
        const notificationList = data.map((trans: any) => {
          const { id, transactionId, transaction } = trans;
          const { transactionStatus, amount, care, payee, note, stateStatus } =
            transaction;
          return {
            id: id,
            session: String(transactionId),
            status:
              stateStatus.name == TransactionStatus.DONE
                ? "Done"
                : transactionStatus.name ?? "unknown",
            statusDisplay:
              stateStatus.name == TransactionStatus.DONE
                ? "Done"
                : transactionStatus.description ?? "unknown",
            amount: amount,
            to: payee.name,
            service: note?.description ?? "",
            approver: `${care.firstName} ${care.lastName}`,
          };
        });
        if (notificationList.length > 0) {
          setCurrentNotification(notificationList.shift());
          setOpen(true);
        }
        setNotiList(notificationList);
      }
    } catch (error) {
      console.error(error);
      if (axios.isAxiosError(error)) {
        const e = error as AxiosError;
        setError((e.response?.data as ApiResponse).message || "");
        setOpenError(true);
      }
    }
  };

  const handleTransOnResend = async (id: number) => {
    try {
      const smsService: SMSService = new SMSService();
      const response = await smsService.resend(id);
      console.log(response);
    } catch (error) {
      console.error(error);
      if (axios.isAxiosError(error)) {
        const e = error as AxiosError;
        setError((e.response?.data as ApiResponse).message || "");
        setOpenError(true);
      }
    }
  };

  const handleTransOnDelete = async (id: number) => {
    try {
      const transactionService: TransactionService = new TransactionService();
      const response = await transactionService.deleteTrans(id);
      console.log(response);
    } catch (error) {
      console.error(error);
      if (axios.isAxiosError(error)) {
        const e = error as AxiosError;
        setError((e.response?.data as ApiResponse).message || "");
        setOpenError(true);
      }
    } finally {
      setItemChanges(!itemChanges);
    }
  };

  const handleTransOnMarkDone = async (id: number) => {
    try {
      const transactionService: TransactionService = new TransactionService();
      const response = await transactionService.markDoneTrans(id);
      console.log(response);
    } catch (error) {
      console.error(error);
      if (axios.isAxiosError(error)) {
        const e = error as AxiosError;
        setError((e.response?.data as ApiResponse).message || "");
        setOpenError(true);
      }
    } finally {
      setItemChanges(!itemChanges);
    }
  };

  const handleNotiOnMarkRead = async (id: number) => {
    try {
      const notificationService: NotificationService =
        new NotificationService();
      const response = await notificationService.markRead(id);
      console.log(response);
    } catch (error) {
      console.error(error);
      if (axios.isAxiosError(error)) {
        const e = error as AxiosError;
        setError((e.response?.data as ApiResponse).message || "");
        setOpenError(true);
      }
    }
  };

  const handleCloseErrorModal = async () => {
    setOpenError(false);
  };

  const handleTransCopySMS = async (id: number) => {
    try {
      const transactionService: TransactionService = new TransactionService();
      const response = await transactionService.getSMSLinkByTransid(id);
      if (response) {
        const { link } = response;
        if (DetectDevice()) {
          let tempInput: HTMLInputElement = document.getElementById(
            "tempCopyIOS"
          ) as HTMLInputElement;
          tempInput.value = link.toString();
          setLink(link.toString());
          onCopy();
        } else {
          CopyToClipBoard(link);
        }
      }
    } catch (error) {
      console.error(error);
      if (axios.isAxiosError(error)) {
        const e = error as AxiosError;
        setError((e.response?.data as ApiResponse).message || "");
        setOpenError(true);
      }
    }
  };

  const onCopy = () => {
    const input: any = document.getElementById("tempCopyIOS");
    setTimeout(() => {
      input.select();
      document.execCommand("copy");
    }, 0);
  };

  return (
    <div className="main-dash-container">
      <input
        type="text"
        className="h-1 w-1 bg-transparent text-transparent outline-transparent fixed left-[-50%]"
        id="tempCopyIOS"
        value={link}
        onChange={() => {}}
      />
      <div className="page-title">Authentic Transactions Dashboard</div>      
      {!isApprover && (
        <Link to="/createrequest">
          <div className="create-new-request">
            <div className="create-element">
              <i className="pi pi-plus"></i>
              <span>Create New Transaction Request</span>
              <i className="pi pi-angle-right icon-circle"></i>
            </div>
          </div>
        </Link>
      )}
      <div className="Transaction-history-container">
        <div className="history-top">
          <div className="title">Past 30 Days</div>
          {/* <div className="input-icon">
            <i className="pi pi-search"></i>
            <input type="text" className="search-input" placeholder="search" />
          </div> */}
        </div>

        <div className="history-list">
          {transactionList.map((tr: any) => (
            <TransactionItem
              id={tr.id} // Assuming transactions have unique IDs
              key={tr.id}
              status={tr.status}
              statusDisplay={tr.statusDisplay}
              amount={tr.amount}
              requestData={tr}
              resendFn={handleTransOnResend}
              deleteFn={handleTransOnDelete}
              markDoneFn={handleTransOnMarkDone}
              copyFn={handleTransCopySMS}
              isApprover={isApprover}
            />
          ))}
          {(!transactionList || transactionList.length == 0) &&
            transactions.map((f: any, i: number) => (
              <div
                key={"sk-" + i}
                className="skeleton-loading transaction-item"
              >
                <Skeleton className="mb-2"></Skeleton>
                <Skeleton width="10rem" className="mb-2"></Skeleton>
                <Skeleton width="5rem" className="mb-2"></Skeleton>
                <Skeleton height="2rem" className="mb-2"></Skeleton>
                <Skeleton width="10rem" height="4rem"></Skeleton>
                <Skeleton width="10rem" className="mb-2"></Skeleton>
                <Skeleton width="10rem" className="mb-2"></Skeleton>
              </div>
            ))}
        </div>
      </div>

      {/* notification dialog */}
      <>
        <Modal
          isOpen={isOpen}
          closeModal={() => {
            handleCloseModal(currentNotification.id);
          }}
        >
          <div className="title text-xl font-bold">
            {currentNotification.status.includes("Approved") && (
              <span>Transaction Approved</span>
            )}
            {currentNotification.status == "Rejected" && (
              <span>Transaction Rejected</span>
            )}
            {currentNotification.status == "Done" && <span>Success!</span>}
          </div>
          <div className="description text-lg">
            {currentNotification.status.includes("Approved") && (
              <span>Your Authentic Transaction request was approved!</span>
            )}
            {currentNotification.status == "Rejected" && (
              <span>Your Authentic Transaction request was rejected</span>
            )}
            {currentNotification.status == "Done" && (
              <span>Your transaction was successfully executed!</span>
            )}
          </div>

          <TransactionItem
            id={currentNotification.transactionId}
            key={currentNotification.id}
            isSimple={true}
            status={currentNotification.status}
            statusDisplay={currentNotification.statusDisplay}
            amount={currentNotification.amount}
            requestData={currentNotification}
            isApprover={isApprover}
            className="!bg-[#E9FBFF] !p-5"
          />

          <div className="footer-action">
            <BasicButton
              label="Close"
              className="bg-[#003049] text-white w-fit"
              onClick={() => {
                handleCloseModal(currentNotification.id);
              }}
            />
          </div>
        </Modal>
      </>
      {/* error dialog */}
      <>
        <Modal isOpen={isOpenError} closeModal={handleCloseErrorModal}>
          <div className="title text-xl font-bold text-red-500">Error:</div>
          <div className="error-message text-red-500">{error}</div>

          <div className="footer-action">
            <BasicButton
              label="Close"
              className="bg-[#003049] text-white w-fit"
              onClick={handleCloseErrorModal}
            />
          </div>
        </Modal>
      </>
    </div>
  );
}
