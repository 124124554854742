import React, { useState , useEffect} from "react";
import { Input, BasicButton } from "src/components/shares";
import { useNavigate } from "react-router-dom";
import { SigninService } from "src/services/signin/signin.service";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage, useFormik } from "formik";
import "./sign-in.scss";
import { setLocalStorageAsync } from "src/utils/localstorage-async";
import { ROUTERS_PATH } from "src/routers/routers";
import useUserStore from 'src/services/user/userstore';
import { useLayoutContext } from 'src/components/layout';


export default function Index() {
  const [token, setToken] = useState<string>("");
  const [error, setError] = useState<string | null>(null);
  const [inProgress, setInProgress] = useState<boolean>(false);
  const setUser = useUserStore((state) => state.setUser);  
  const { ShowHideLogOut } = useLayoutContext();
  const navigate = useNavigate();

  useEffect(() => {       
    ShowHideLogOut();    
  }, []);



  const validationSchema = Yup.object({
    email: Yup.string()
      .required("Email is required")
      .min(2, "Invalid email length")
      .email("Invalid email format!")
      .matches(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/, "Invalid email format!")      
  });

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    onSubmit: (values) => handleOnSubmit(values),
  });

  const handleOnSubmit = async (values: any) => {
    setInProgress(true);
    console.log("submit");
    try {
      const body = {
        email: values.email,
        token,
      };
      const signinService: SigninService = new SigninService();
      const response: any = await signinService.signIn(body);
      const { user, tokens } = response;      

      if (user && tokens) { 
        setUser(user);        
        await setLocalStorageAsync("tokens", JSON.stringify(tokens));
        const { token } = tokens.access;        
        localStorage.setItem("token", token);
        localStorage.setItem("email", user.email);
        localStorage.setItem("isApprover", user.orgUsers?.role?.isApprover ?? null);
        navigate(ROUTERS_PATH.AUTH_REQUESTER);        
      } else {
        throw new Error("Could not authorize!");
      }
    } catch (error) {
      console.error("Sign-in error:", error);
      setError("Invalid credentials");
    } finally {
      setInProgress(false);
    }
  };


  return (
    <div className="h-full flex flex-col gap-1 mt-4 px-5">
      <Formik
        initialValues={{ email: "" }}
        validationSchema={validationSchema}
        onSubmit={handleOnSubmit}
        validateOnChange={true}
        validateOnBlur={true}
      >
        <Form>
          <Input id="email" name="email" type="text" label="Email" />

          {error && <p style={{ color: "red" }}>{error}</p>}
          <div className="login-btn flex justify-center mt-20">
            <BasicButton
              label="Log in"
              className="bg-[#003049] text-white w-fit !py-3 px-10 rounded-[1rem]"
              type="submit"
              loading={inProgress}
            />
          </div>
        </Form>
      </Formik>
      {/* <div className="no-account flex justify-center">
        <a className="text-[#0D76C2]" href="#">
          No account? Create one now
        </a>
      </div> */}
    </div>
  );
}
