import { API } from "../api-constants";
import { HttpClient } from "../common-services/httpclient.service";

export class IronVestService extends HttpClient {
  private readonly servivePath = API.IV;
  constructor() {
    super();
  }

  public async enrollTransaction(id: number, email: string) {
    const url = this.servivePath.ENROLL.replace(":id", id.toString());
    return await this.post(url, { email });
  }

  public async verifyTransaction(id: number) {
    const url = this.servivePath.VERIFY_TRANS.replace(":id", id.toString());
    return await this.post(url, {});
  }

  public async verify(email: string) {
    return await this.postTry(
      this.servivePath.VERIFY,
      { times: 2, timeout: 1000 },
      { email }
    );
  }

  public async validatesession(email: string, sessionid: string) {
    return await this.postTry(
      this.servivePath.ValidateSession,
      { times: 3, timeout: 1000 },
      { email, sessionid }
    );
  }

}
