import { API } from "../api-constants";
import { HttpClient } from "../common-services/httpclient.service";
import { UserListModel, NewUser } from "../../models/user.model";

export class UserService extends HttpClient {
  private readonly servivePath = API.USER;
  constructor() {
    super();
  }

  public async getUsers(data: UserListModel) {
    return await this.get(this.servivePath.USERS, { params: data });
  }

  public async createUser(data: NewUser) {
    return await this.post(this.servivePath.USERS, data);
  }
}
