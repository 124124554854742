import React from "react";
import { RouterConstant } from "src/routers/index";
import "primereact/resources/themes/bootstrap4-light-blue/theme.css";
import "src/styles/global.scss";
import { Navigate, BrowserRouter, Route, Routes } from "react-router-dom";
import MainLayout from "./components/layout";

import PageNotFound from "src/containers/not-found";
import SignInPage from "src/containers/sign-in";
import Dashboard from "src/containers/auth-dashboard";
import ApproveRequestPage from "./containers/approve-request";
import AuthSession from "./containers/auth-session";
import CreateRequestPage from "./containers/create-request";
import ApproveNotEnrollPage from "./containers/approve-not-enroll";
import AuthApproverPage from "./containers/auth-approver";
import AuthRequesterPage from "./containers/auth-requester";
import CreateUserPage from "./containers/create-user";

function App() {
  const { ROUTERS_PATH } = RouterConstant;

  return (
    <BrowserRouter>
      <MainLayout>
        <Routes>
          <Route path={ROUTERS_PATH.NOT_FOUND} element={<PageNotFound />} />
          <Route path={ROUTERS_PATH.SIGN_IN} element={<SignInPage />} />
          <Route path={ROUTERS_PATH.DASHBOARD} element={<Dashboard />} />
          <Route
            path={ROUTERS_PATH.DASHBOARD}
            element={<Dashboard />}
          />
          <Route
            path={ROUTERS_PATH.APPROVE_REQUEST + "/:session"}
            element={<ApproveRequestPage />}
          />
          <Route path={ROUTERS_PATH.AUTH_SESSION} element={<AuthSession />} />
          <Route
            path={ROUTERS_PATH.CREATE_REQUEST}
            element={<CreateRequestPage />}
          />
          <Route
            path={ROUTERS_PATH.CREATE_USER}
            element={<CreateUserPage />}
          />
          <Route
            path={ROUTERS_PATH.APPROVE_NOT_ENROLL + "/:session"}
            element={<ApproveNotEnrollPage />}
          />
          <Route
            path={ROUTERS_PATH.AUTH_APPROVER + "/:session"}
            element={<AuthApproverPage />}
          />
          <Route
            path={ROUTERS_PATH.AUTH_REQUESTER}
            element={<AuthRequesterPage />}
          />
          <Route
            path={ROUTERS_PATH.ROOT_PATH}
            element={<Navigate to={ROUTERS_PATH.SIGN_IN} replace />}
          />
          <Route
            path="*"
            element={<Navigate to={ROUTERS_PATH.NOT_FOUND} replace />}
          />
        </Routes>
      </MainLayout>
    </BrowserRouter>
  );
}

export default App;
