// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.not-enroll-actions {
  width: 100%
}
.not-enroll-actions .actions-card {
  margin: auto;
  width: 86%;
  border-radius: 1rem;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem
}
.not-enroll-actions .actions-card .content {
  font-size: 17px;
  font-weight: 700
}
.not-enroll-actions .actions-card .actions {
  width: 100%;
  text-align: center
}
.not-enroll-actions .actions-card .actions .skip-btn {
  font-weight: 700;
  --tw-text-opacity: 1;
  color: rgb(5 172 177 / var(--tw-text-opacity))
}

.bg-approval-request {
  opacity: 0.65;
  background-size: 250%;
  background-position: 69% 16%
}`, "",{"version":3,"sources":["webpack://./src/containers/approve-not-enroll/approve-not-enroll.scss"],"names":[],"mappings":"AACE;EAAA;AAAA;AAEE;EAAA,YAAA;EAAA,UAAA;EAAA,mBAAA;EAAA,kBAAA;EAAA,yDAAA;EAAA,mBAAA;EAAA,sBAAA;EAAA,kBAAA;EAAA;AAAA;AAEE;EAAA,eAAA;EAAA;AAAA;AAIA;EAAA,WAAA;EAAA;AAAA;AAIE;EAAA,gBAAA;EAAA,oBAAA;EAAA;AAAA;;AAON;EAAA,aAAA;EACA,qBAAA;EACA;AAFA","sourcesContent":[".not-enroll-actions {\n  @apply w-full;\n  .actions-card {\n    @apply w-[86%] m-auto py-2 px-4 bg-white rounded-2xl;\n    .content {\n      @apply font-bold text-[17px];\n    }\n\n    .actions {\n      @apply w-full text-center;\n      .setup-bio {\n      }\n      .skip-btn {\n        @apply font-bold text-[#05ACB1];\n      }\n    }\n  }\n}\n\n.bg-approval-request {\n  @apply opacity-65;\n  background-size: 250%;\n  background-position: 69% 16%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
