import React, { useEffect, useMemo, useState } from "react";

import { ApproverType, STATE_VIEW, dataVerify } from "./type";
import { approverlistarrow } from "src/assets";
import {
  BasicButton,
  CameraInstruction,
  Input,
  Modal,
  StreamVideo,
} from "src/components/shares";
import { motion } from "framer-motion";
import { useNavigate , useLocation} from "react-router-dom";
import { ROUTERS_PATH } from "src/routers/routers";
import "./create-request.scss";
import { IVAuth } from "src/services/auth/IV-auth";
import { BiometricOptionType } from "src/services/auth/type";
import { Form, Formik, useFormik } from "formik";
import * as Yup from "yup";
import { TransactionService } from "src/services/transaction/transaction.service";
import {
  CreateTransModel,
  TransListModel,
} from "src/services/transaction/type";
import { UserService } from "src/services/user/user.service";
import { UserListModel } from "src/models/user.model";
import axios, { AxiosError } from "axios";
import { ApiResponse } from "src/utils";
import { PermissionIVEnum } from "src/services/auth/permission.enum";
import useUserStore from 'src/services/user/userstore';

export default function CreateRequestPage() {
  const location = useLocation();
  const navigate = useNavigate();  
  const [stateView, setStateView] = useState<string>(STATE_VIEW.CLOSE);
  const [approverData, setApproverData] = useState<ApproverType[]>([]);
  const [approverSelect, setApproverSelect] = useState<
    ApproverType | undefined
  >(undefined);
  const [newApprover, setNewApprover] = useState<ApproverType>({
    firstName: "",
    lastName: "",
    cellPhone: "",
    email: "",
    status: 1,
  });
  const [error, setError] = useState<string | null>(null);
  const [inProgress, setInProgress] = useState<boolean>(false);
  const [isVideoLoaded, setIsVideoLoaded] = useState<boolean>(false);
  const [sessionId, setSessionId] = useState<string>("");
  const [isOpen, setOpen] = useState(false);  
  const loggedInUser = useUserStore((state) => state.user);
  const setUser = useUserStore((state) => state.setUser);  

  const [intervalRequest, setIntervalRequest] = useState<any>();
  // State to track if the motion.div is open
  const [isMotionDivOpen, setIsMotionDivOpen] = useState(false);
  const iv = new IVAuth();

  useEffect(() => {    
    if (loggedInUser) {
      setUser(loggedInUser);
    }        
    iv.init();
    handleRequestPermission();
    getSessionId();    
    getApprover();
  }, []);

  const getApprover = async () => {
    try {
      const requestParams: UserListModel = {
        limit: 999,
        page: 1,
      };

      const userService: UserService = new UserService();
      const response: any = await userService.getUsers(requestParams);
      const { data } = response;
      setApproverData(data);
    } catch (error) {
      console.error(error);
    }
  };

  const getSessionId = async () => {
    try {
      const transactionService: TransactionService = new TransactionService();
      const response = await transactionService.getTransSessionId();
      if (response) {
        setSessionId(response);
        iv.updateCSID(response);

      }
    } catch (error) {
      console.error(error);
    }
  };

  const validationSchema = Yup.object({
    amount: Yup.string().required(),
    payee: Yup.string().required(),
    note: Yup.string(),
  });

  const handleRequestPermission = async () => {    
    const permission = await iv.getPermissionsStatus();
    console.log(permission);
    if (permission && permission.camera != "granted") {
      console.log("request camera");
      iv.requestPermission(PermissionIVEnum.CAMERA);
    } else {
      const bio: BiometricOptionType = {
        actionId: "actionId",
        opacity: 0.6,
      };

      setTimeout(() => {
        iv.startBio(bio, "ironvest_video");
      }, 100);
      setIsVideoLoaded(true);
    }
  };


  const formik = useFormik({
    initialValues: {
      note: "",
      payee: "",
      amount: "",
    },
    onSubmit: (values) => handleOnSubmit(values),
  });

  const handleOnSubmit = async (values: any) => {
    setInProgress(true);
    const token = localStorage.getItem("token") ?? "unknown";    
    const { note, payee, amount } = values;
    const dataRequest: CreateTransModel = {
      careUserId: approverSelect?.id ?? 0,
      note: note,
      payee: payee,
      amount: Number(amount),
      sessionId: sessionId,
    };

    setTimeout(async () => {      
      
      try {                
        const transactionService: TransactionService = new TransactionService();
        const response = await transactionService.createTrans(dataRequest); 
        
        if (response) {               
          iv.updateCSID(token);             
          iv.endBio();
          navigate(ROUTERS_PATH.DASHBOARD);          
        }    
      } catch (error: any) {          
          if (axios.isAxiosError(error)) {
            const e = error as AxiosError;          
            const errMessage = (e.response?.data as any).message;   
            console.log('AuthenticAction Validate API End Point Response data:', JSON.stringify(e.response?.data, null, 2));            
            setError(errMessage || 'Failed to verify transaction. Please try again');           
            setOpen(true);
        }
      } finally {
        setInProgress(false);       
      }
    }, 3000);
  };

  
  const handleStateView = () => {
    const result =
      stateView === STATE_VIEW.CLOSE ? STATE_VIEW.SELECT : STATE_VIEW.CLOSE;
      console.log(result);
    setStateView(result);    

  };

  const handleSelectApprover = (value: ApproverType) => {    
    setApproverSelect(value);
    setStateView(STATE_VIEW.CLOSE);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleNavigateToCreateUser = () => {
    iv.endBio();
    setTimeout(async () => {
      navigate(ROUTERS_PATH.CREATE_USER);
    }, 100);  
  };  

  const goBack = () => {
      iv.endBio();
      setTimeout(async () => {
        navigate(ROUTERS_PATH.DASHBOARD);
      }, 1000);  
    
  };


  return (
    <div className="w-full h-full flex flex-col text-iron-blue">
          <div className="page-title-actions">        
            <i className="pi pi-angle-left icon-circle-simple !w-8 !h-8 mr-2" onClick={goBack}></i>        
            <span>New transaction verification request</span>
          </div>
          <div className="ironvest-video-warpper">
            <div id="ironvest_video" className="ironvest-video">
              {!isVideoLoaded && (
                <div className="camrea-instruction absolute z-[0] w-full sm:top-0 md:top-[6%]">
                  <CameraInstruction></CameraInstruction>
                </div>
              )}
              
              <div className={`form-creation-container ${isVideoLoaded ? 'video-loaded' : ''}`}>
                <Formik
                  initialValues={{
                    note: "",
                    payee: "",
                    amount: "",
                  }}
                  validationSchema={validationSchema}
                  onSubmit={handleOnSubmit}
                  validateOnChange={true}
                  validateOnBlur={true}
                >
                  {/* {({ isValid }) => ( */}
                  <Form className="form-creation">              
                    <div className="flex flex-col mx-6 text-[14px] leading-4 gap-5 h-fit">
                    <div onClick={handleStateView} className="approver-drop-down-container">
                      <div className="approver-drop-down-content">
                        <span onClick={handleStateView} className="label text-[#494949]">Who needs to verify this transaction?</span>
                        {stateView === STATE_VIEW.CLOSE ? (
                          <div className="approver-drop-down-close">  
                              <span className={`${approverSelect ? "approver-item" : "text-[#AAAAAA]" }`}>
                                {approverSelect ? 
                                  (`${approverSelect.firstName} ${approverSelect.lastName}`) : 
                                  (<input className="outline-none text-iron-blue bg-transparent" placeholder="Search..."/>
                                )}
                              </span>
                          </div>
                        ) : null}                    
                        {stateView === STATE_VIEW.SELECT && ( 
                          <motion.div 
                            id="list-approver" 
                            className="approver-drop-down-open"
                            animate={{ opacity: 1, scale: 1 }}
                            transition={{
                              duration: 0.0,
                              delay: 0.15,
                              ease: [0, 0.71, 0.2, 1.01],
                            }}>                    
                                {approverData.map((item, index) => (
                                  <span
                                    onClick={() => handleSelectApprover(item)}
                                    key={item.id}
                                    className="approver-item hover:text-iron-teal"
                                  >
                                    {`${item.firstName} ${item.lastName}`}                              
                                  </span>
                                ))}   
                                {<span className="approver-item hover: hover:text-iron-teal" onClick={() => handleNavigateToCreateUser()}>New</span>}
                          </motion.div>
                        )}
                      </div>
                        <div className="dropdown-arrow"><img src={ approverlistarrow } className={`${stateView === STATE_VIEW.CLOSE ? "rotate-180" : "" }`}/></div>                  
                    </div>

                      <Input
                        id="payee"
                        name="payee"
                        type="text"
                        label="Payee"
                        placeholder="to whom"
                        border={true}
                      ></Input>

                      <Input
                        id="amount"
                        name="amount"
                        type="currency"
                        label="Amount"
                        placeholder="amount"
                        border={true}
                      ></Input>

                      <Input
                        id="note"
                        name="note"
                        type="text"
                        label="Note"
                        placeholder="note"
                        border={true}
                      ></Input>
                    </div>

                    <div className="flex items-center justify-center mt-[1rem]">
                      <BasicButton
                        label="Submit"
                        className="bg-iron-blue text-white w-[45%] text-2xl/loose tracking-normal py-[0.625rem]"
                        type="submit"
                        loading={inProgress}
                      />
                    </div>
                  </Form>
                  {/* )} */}
                </Formik>            
              </div>          
          </div>
      </div>
      <>
        <Modal isOpen={isOpen} closeModal={handleCloseModal}>
          <div className="title text-xl font-bold text-red-500">Error:</div>
          <div className="error-message text-red-500">{error}</div>

          <div className="footer-action">
            <BasicButton
              label="Close"
              className="bg-[#003049] text-white w-fit"
              onClick={handleCloseModal}
            />
          </div>
        </Modal>
      </>
    </div>
  );
}
