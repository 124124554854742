import React, { useEffect, useRef, useState } from "react";
import verifyIcon from "src/assets/verify-icon.svg";
import lockIcon from "src/assets/lock-icon.svg";
import mailIcon from "src/assets/mail-icon.svg";
import locationIcon from "src/assets/location-icon.svg";
import calendarIcon from "src/assets/calendar-icon.svg";
import {
  BasicButton,
  CameraInstruction,
  Input,
  Modal,
  StreamVideo,
} from "src/components/shares";
import { RequestDataType } from "./type";
import "./approve-request.scss";
import { ErrorResponse, Link, useNavigate, useParams } from "react-router-dom";
import { IVAuth } from "src/services/auth/IV-auth";
import { BiometricOptionType } from "src/services/auth/type";
import { TransactionService } from "src/services/transaction/transaction.service";
import { ApiResponse, FormatingDate } from "src/utils";
import { ROUTERS_PATH } from "src/routers/routers";
import { Formik, useFormik, Form } from "formik";
import * as Yup from "yup";
import { setLocalStorageAsync } from "src/utils/localstorage-async";
import axios, { AxiosError } from "axios";
import { Button } from "primereact/button";
import { PermissionIVEnum } from "src/services/auth/permission.enum";
import { Tooltip } from "primereact/tooltip";
import { Chip } from "primereact/chip";
import { notVerifiedIcon } from "src/assets";
import useUserStore from 'src/services/user/userstore';
import { useLayoutContext } from 'src/components/layout';

export default function ApproveRequestPage() {

  const { session } = useParams();
  const [requestData, setRequestData] = useState<RequestDataType | undefined>(
    undefined
  );
  const [inProgressApprove, setInProgressApprove] = useState<boolean>(false);
  const [inProgressDecline, setInProgressDecline] = useState<boolean>(false);
  const [transId, setTransId] = useState<number>(0);
  const [error, setError] = useState<string>("");
  const [RequesterName, setRequesterName] = useState<string>("");
  const [isVideoLoaded, setIsVideoLoaded] = useState<boolean>(false);
  const [isRequestorEnrolled, setIsRequestorEnrolled] =
    useState<boolean>(true);

  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [isOpenPopupConfirm, setIsOpenPopupConfirm] = useState(false);
  const loggedInUser = useUserStore((state) => state.user);
  const setUser = useUserStore((state) => state.setUser);  
  const clearUser = useUserStore((state) => state.clearUser);  
  const { handleLogout } = useLayoutContext();
  const navigate = useNavigate();
  const iv = new IVAuth();

  useEffect(() => {    
    iv.init();
    getRequestData();
    const bio: BiometricOptionType = {
      actionId: "actionId",
      opacity: 0.6,
    };
    if (isVideoLoaded) {
      
      setTimeout(() => {        
        iv.startBio(bio, "ironvest_video");
      }, 100);

    } else {
      handleRequestPermission();
    }
  }, [isVideoLoaded]);

  const validationSchema = Yup.object({
    amount: Yup.string().required("Amount is required"),
  });

  const initialValues = {
    amount: "",
    type: "APPROVE",
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: (values) => handleOnSubmit(values),
  });  

  const getRequestData = async () => {
    try {
      const transactionService: TransactionService = new TransactionService();
      const response = await transactionService.getTransByToken(session || "");
      if (response) {
        const { transaction, user, tokens, isRequestorEnrolled } = response;

        if (isRequestorEnrolled != null || isRequestorEnrolled != undefined) {
          setIsRequestorEnrolled(isRequestorEnrolled);
        }


        if (user && tokens) {
          setUser(user);          
          await setLocalStorageAsync("tokens", JSON.stringify(tokens));
          localStorage.setItem("isApprover", JSON.stringify(true));
          const { token } = tokens.access;
          localStorage.setItem("token", token);
        } else {
          throw new Error("Could not authorize!");
        }

        if (transaction) {
          const { id, note, payee, updatedAt, requestor, sessionId } =
            transaction;
          setTransId(id);
          const approveRequestData = {
            to: payee.name,
            service: note.description,
            dateTime: FormatingDate(updatedAt),
          };
          setRequestData(approveRequestData);

          if (requestor) {                      
            setRequesterName(requestor.firstName + " " + requestor.lastName || "Unknown");
          }
          
          // Become the approve session id as unique and diffrent value from the requestor 
          const sessionidPrefix = 'aprvcsid'
          let csid = sessionidPrefix + sessionId;

          if (csid && user) {            
            iv.update(csid, user.email);
          }
        }
      } else {
        throw new Error("Something went wrong!");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleRequestPermission = async () => {    
    const permission = await iv.getPermissionsStatus();
    console.log(permission);
    if (permission && permission.camera != "granted") {
      console.log("request camera");
      iv.requestPermission(PermissionIVEnum.CAMERA);
    } else {
      setIsVideoLoaded(true);
    }
  }; 

  const handleOnSubmit = (values: any) => {
    const { amount } = values;
    const amountNumber = Number(amount);
    console.log(amountNumber);
    if (values.type == "APPROVE") {
      handleApprove(values);
    } else {
      handleDecline(values);
    }
  };

  const handleApprove = async (values: any) => {
    setInProgressApprove(true);
    const { amount } = values;
    

    const transactionService: TransactionService = new TransactionService();

    try {
        const response = await transactionService.approveTrans(transId,Number(amount.replace("$", "")));
        setIsOpenPopupConfirm(true);
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        const e = error as AxiosError;
        setError((e.response?.data as ApiResponse).message || "");
        setIsOpenPopup(true);
      }
    } finally {
      setInProgressApprove(false);
      const token = localStorage.getItem("token");
      iv.updateCSID(token);      
    }
  };
  const handleDecline = async (values: any) => {
    setInProgressDecline(true);
    console.log("Decline");
    try {
      const transactionService: TransactionService = new TransactionService();
      const response = await transactionService.rejectTrans(transId);
      console.log(response);
      setIsOpenPopupConfirm(true);      
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const e = error as AxiosError;        
        setIsOpenPopup(true);
      }
    } finally {
      setInProgressDecline(false); 
    }
  };

  const handleCloseModalConfirm = () => {            
    handleLogout();
  }  

  const handleAuthSession = () => {    
    iv.endBio();
    navigate(`/authsession?transId=${transId}`);
  };

  const handleCloseModal = () => {
    setIsOpenPopup(false);
  };

  return (
    <div className="page-body">
      <Tooltip target=".ellipsis-behavior" />
      <div className="page-title-actions">Respond to transaction request</div>
      <div className="ironvest-video-warpper">
          <div id="ironvest_video" className="ironvest-video">
            {!isVideoLoaded && (
              <div className="camrea-instruction absolute z-[0] w-full sm:top-0 md:top-[6%]">
                <CameraInstruction></CameraInstruction>
              </div>
            )}

            <div className="response-form">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleOnSubmit}
                validateOnChange={true}
                validateOnBlur={true}
              >
                {({ isValid, setValues, setTouched, values }) => (
                  <Form>
                    <div className="response-form-card">
                      <div className="title-form">
                        {isRequestorEnrolled ? (
                          <div className="verify-icon">
                            <img src={verifyIcon} alt="verifyIcon" />
                            <span className="text-[10px] leading-4 font-semibold pr-2">
                              Verified!
                            </span>
                          </div>
                        ) : (
                          <>
                            <Chip
                              className="w-fit px-2 py-[2px] bg-gradient-to-r from-[#EF520E] to-[#F61111]"
                              template={
                                <>
                                  <img
                                    className="h-[18px] w-[18px] ml-0 mr-1"
                                    src={notVerifiedIcon}
                                    alt="not-verified"
                                  />
                                  <span className="font-semibold text-xs text-white whitespace-nowrap">
                                    Not Verified
                                  </span>
                                </>
                              }
                            />
                          </>
                        )}
                        <span className="verify-text">
                          {RequesterName} has sent a request:
                        </span>
                      </div>
                      <div className="see-session">
                        <Button
                          className="view-session"
                          label="See Authentic Session"
                          onClick={handleAuthSession}
                        ></Button>
                      </div>
                      <Input
                        id="amount"
                        name="amount"
                        type="currency"
                        label="Fill In Amount"
                        placeholder="amount"
                        border={true}
                        className="!w-44 !bg-transparent"
                      ></Input>
                      <div className="info-row">
                        <img
                          src={mailIcon}
                          alt="mailIcon"
                          className="w-[24px] h-[24px]"
                        />
                        <span>To</span>
                        <span
                          className="font-bold ellipsis-behavior"
                          data-pr-tooltip={requestData?.to}
                        >
                          {requestData?.to}
                        </span>
                      </div>
                      <div className="info-row">
                        <img
                          src={locationIcon}
                          alt="locationIcon"
                          className="w-[24px] h-[24px]"
                        />
                        <span>For</span>
                        <span
                          className="font-bold ellipsis-behavior"
                          data-pr-tooltip={requestData?.service}
                        >
                          {requestData?.service}
                        </span>
                      </div>
                      <div className="info-row">
                        <img
                          src={calendarIcon}
                          alt="calendarIcon"
                          className="w-[24px] h-[24px]"
                        />
                        <span>Date</span>
                        <span className="font-bold">{requestData?.dateTime}</span>
                      </div>
                    </div>

                    <div className="decision-buttons">
                      <BasicButton
                        label="Approve"
                        className="bg-iron-blue !text-white w-fit"
                        onClick={() => {
                          setValues({ amount: values.amount, type: "APPROVE" });
                          setTouched({ amount: true });
                        }}
                        disabled={!isValid}
                        type="submit"
                        loading={inProgressApprove}
                      />
                      <BasicButton
                        label="Decline"
                        className="bg-white text-icon-blue w-fit"
                        onClick={() => {
                          setValues({ amount: "0", type: "DECLINE" });
                        }}
                        type="submit"
                        loading={inProgressDecline}
                      />
                    </div>
                  </Form>
                )}
              </Formik>          
            </div>
        </div>
      </div>

      <>
        <Modal isOpen={isOpenPopup} closeModal={handleCloseModal}>
          <div className="title text-xl font-bold text-red-500">Error:</div>
          <div className="error-message text-red-500">{error}</div>

          <div className="footer-action">
            <BasicButton
              label="Close"
              className="bg-[#003049] text-white w-fit"
              onClick={handleCloseModal}
            />
          </div>
        </Modal>


        <Modal isOpen={isOpenPopupConfirm} closeModal={handleCloseModalConfirm}>
        
          <div className="title text-xl font-bold">    
              <span>Your instruction was executed successfully!</span>            
          </div>

   
          <div className="footer-action">
            <BasicButton
              label="Close"
              className="bg-[#003049] text-white w-fit"
              onClick={handleCloseModalConfirm}
            />
          </div>
        </Modal>
      </>
    </div>
  );
}
