import verifyIcon from "src/assets/verify-icon.svg";
import lockIcon from "src/assets/lock-icon.svg";
import mailIcon from "src/assets/mail-icon.svg";
import locationIcon from "src/assets/location-icon.svg";
import calendarIcon from "src/assets/calendar-icon.svg";
import accountIcon from "src/assets/account_circle.svg";
import backwardIcon from "src/assets/backward.svg";
import playIcon from "src/assets/play-arrow.svg";
import forwardIcon from "src/assets/forward.svg";
import biometricsIcon from "src/assets/biometrics-icon.svg";
import redoIcon from "src/assets/redo-icon.svg";
import notVerifiedIcon from "src/assets/not-verified.svg"
import personBg2 from "src/assets/person-bg-2.jpeg";
import play2Icon from "src/assets/play-2.svg";
import pause2Icon from "src/assets/pause-2.svg";
import backward2Icon from "src/assets/backward-2.svg";
import approverlistarrow from "src/assets/approverlistarrow.svg";

export {
  verifyIcon,
  lockIcon,
  mailIcon,
  locationIcon,
  calendarIcon,
  accountIcon,
  backwardIcon,
  playIcon,
  forwardIcon,
  biometricsIcon,
  redoIcon,
  notVerifiedIcon,
  personBg2,
  play2Icon,
  pause2Icon,
  backward2Icon,
  approverlistarrow
};
