import { AUTH } from "./internal/auth";
import { TRANSACTION } from "./internal/transaction";
import { USER } from "./internal/user";
import { NOTIFICATION } from "./internal/notification";
import { SMS } from "./internal/sms";
import { IV } from "./internal/ironvest";
export const API = {
  AUTH,
  TRANSACTION,
  USER,
  NOTIFICATION,
  SMS,
  IV,
};
