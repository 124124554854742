// Utility functions for localStorage

// Async function to set value in localStorage
export const setLocalStorageAsync = async (key: string, value: any) => {
  try {
    await new Promise((resolve) => {
      localStorage.setItem(key, JSON.stringify(value));
      resolve(true);
    });
  } catch (error) {
    console.error("Error setting value in localStorage:", error);
  }
};

// Async function to get value from localStorage
export const getLocalStorageAsync = async (key: string) => {
  try {
    const value = await new Promise((resolve) => {
      const storedValue = localStorage.getItem(key);
      resolve(storedValue ? JSON.parse(storedValue) : null);
    });
    return value;
  } catch (error) {
    console.error("Error getting value from localStorage:", error);
    return null;
  }
};
