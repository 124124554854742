// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.default-input {
  width: 100%;
  gap: 0.25rem;
  border-radius: 16px;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-bg-opacity: 0.65;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  line-height: 1rem;
}

.error-field {
  --tw-text-opacity: 1;
  color: rgb(240 68 56 / var(--tw-text-opacity));
}
.error-field .label {
  --tw-text-opacity: 1;
  color: rgb(152 162 179 / var(--tw-text-opacity));
}
.error-field ::placeholder {
  --tw-text-opacity: 1;
  color: rgb(240 68 56 / var(--tw-text-opacity));
}

::placeholder {
  --tw-text-opacity: 1;
  color: rgb(0 48 73 / var(--tw-text-opacity));
}

::-ms-input-placeholder {
  /* Edge 12 -18 */
  --tw-text-opacity: 1;
  color: rgb(0 48 73 / var(--tw-text-opacity));
}`, "",{"version":3,"sources":["webpack://./src/components/shares/input/input.scss"],"names":[],"mappings":"AACE;EAAA,WAAA;EAAA,YAAA;EAAA,mBAAA;EAAA,yDAAA;EAAA,qBAAA;EAAA,kBAAA;EAAA,mBAAA;EAAA,qBAAA;EAAA,wBAAA;EAAA;AAAA;;AAIA;EAAA,oBAAA;EAAA;AAAA;AAEE;EAAA,oBAAA;EAAA;AAAA;AAIA;EAAA,oBAAA;EAAA;AAAA;;AAUF;EAAA,oBAAA;EAAA;AAAA;;AAGF;EACE,gBAAA;EACA,oBAAA;EAAA,4CAAA;AADF","sourcesContent":[".default-input {\n  @apply bg-white bg-opacity-65 rounded-[16px] px-4 py-[0.625rem] w-full gap-1 leading-4;\n}\n\n.error-field {\n  @apply text-red-500;\n  .label {\n    @apply text-gray-400;\n  }\n\n  ::placeholder {\n    @apply text-red-500;\n  }\n\n  ::-ms-input-placeholder {\n    /* Edge 12 -18 */\n    @apply text-red-500;\n  }\n}\n\n::placeholder {\n  @apply text-iron-blue;\n}\n\n::-ms-input-placeholder {\n  /* Edge 12 -18 */\n  @apply text-iron-blue;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
