import React from "react";
import { Button, ButtonPassThroughOptions } from "primereact/button";
import { ButtonType } from "./type";
import { Tooltip } from 'primereact/tooltip';

export default function Index(props: ButtonType) {
  const { onClick, label, disabled, className, type, loading } = props;
  const pt: ButtonPassThroughOptions = type ? { root: { type: type } } : {};

  return (
    <Button
      pt={pt}
      className={"px-9 py-4 border rounded-xl text-xl " + className}
      label={label}
      onClick={onClick}
      disabled={disabled}
      loading={loading}
    />
  );
}
