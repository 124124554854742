import React from "react";
import { biometricsIcon } from "src/assets";
import "./camera-instruction.scss";

export default function Index(props: any) {
  return (
    <>
      <div className="camera-instruction">
        <div className="portrait-icon">
          <img
            src={biometricsIcon}
            alt="biometricsIcon"
            className="portrait-icon-img"
          />
        </div>
        <div className="content-line">
          <div className="content font-semibold text-sm">For best results:</div>
        </div>
        <div className="content-line">
          <div className="content">
            Use a <span className="font-bold text-[15px]">light source</span> to
            illuminate your face.
          </div>
        </div>
        <div className="content-line">
          <div className="content">
            Keep your{" "}
            <span className="font-bold text-[15px]">face centered</span> within
            the video preview.
          </div>
        </div>
        <div className="content-line">
          <div className="content">
            <span className="font-bold text-[15px]">Hold still</span> for 3
            seconds during the capture.
          </div>
        </div>
      </div>
    </>
  );
}
