import { useEffect, useState } from "react";
import {
  BasicButton,
  CameraInstruction,
  Modal,
  StreamVideo,
} from "src/components/shares";
import "./auth-requester.scss";
import { Button } from "primereact/button";
import { IronVestService } from "src/services/ironvest/ironvest.service";
import { useNavigate } from "react-router-dom";
import { ROUTERS_PATH } from "src/routers/routers";
import { IVAuth } from "src/services/auth/IV-auth";
import { BiometricOptionType } from "src/services/auth/type";
import { PermissionIVEnum } from "src/services/auth/permission.enum";
import { useLayoutContext } from 'src/components/layout';


export default function AuthRequesterPage() {
  const [isVideoLoaded, setIsVideoLoaded] = useState<boolean>(false);
  const [loadingVerify, setLoadingVerify] = useState<boolean>(true);
  const [isOpenError, setOpenError] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const navigation = useNavigate();  
  const { ShowHideLogOut } = useLayoutContext();
  const iv = new IVAuth();
    

  let email = "";      
  let sessionid = "";    

  useEffect(() => {       
    ShowHideLogOut();
    email = localStorage.getItem("email") ?? "";      
    sessionid = localStorage.getItem("token") ?? ""; 
    iv.init();       
    iv.update(sessionid,email);
    handleRequestPermission(); 
  }, []);

 
  const handleRequestPermission = async () => {    
    const permission = await iv.getPermissionsStatus();    
    if (permission && permission.camera != "granted") {      
      iv.requestPermission(PermissionIVEnum.CAMERA);
      setTimeout(handleRequestPermission, 200);
    } else {
      const bio: BiometricOptionType = {
        actionId: "actionId",
        opacity: 1,
      };
      setTimeout(() => {
        iv.startBio(bio, "ironvest_videoLoginPage");
      }, 100);
      setIsVideoLoaded(true);
      setTimeout(() => {
        handleOnVerifyUser();  
      }, 3000);      
    }
  };


  const handleOnVerifyUser = async () => {
    setLoadingVerify(true);
    setError(null);
    setOpenError(false);    
    email = localStorage.getItem("email") ?? "";      
    sessionid = localStorage.getItem("token") ?? "";       
    try {
      const ironVestService: IronVestService = new IronVestService();        
      const response = await ironVestService.validatesession(email,sessionid);
      
        setTimeout(() => {        
          if(response){                
              setLoadingVerify(false);   
              iv.endBio();                     
              setTimeout(() => {
                navigation(ROUTERS_PATH.DASHBOARD);
              }, 50);                     
          } 
          else{
            setError("Cannot verify! Do you want to retry?");
            setOpenError(true);
          }
          }, 1000);
    
    } catch (error) {
      console.error(error);            
      setError("Cannot verify! Do you want to retry?");
      setOpenError(true);
    }    
  
    finally {         
    }
  };

  const handleCloseErrorModal = () => {
    setOpenError(false);
  };

  const handleOnCancel = () => {    
    iv.endBio();
    navigation(ROUTERS_PATH.SIGN_IN);
  };

  return (
    <>
      <div className="h-full flex flex-col gap-2 mt-4 px-5 relative items-center">
        <div className="title text-2xl/loose font-semibold tracking-normal text-iron-blue mx-auto">
          Verify it's you!
        </div>
        <div className="description mx-auto">Verify with your biometrics.</div>

        <div className="verify-camera mt-10 mb-5">
          <StreamVideo
            id="ironvest_videoLoginPage"
            className="stream-video-verify"
            handleOnLoaded={setIsVideoLoaded}
          ></StreamVideo>
        </div>

        {!isVideoLoaded && (
          <div className="camrea-instruction absolute top-[14%]">
            <CameraInstruction></CameraInstruction>
          </div>
        )}

        <div className="verify-process mx-auto">
          {!loadingVerify ? (
            error ? (
              <i className="pi pi-times text-red-500 text-2xl"></i>
            ) : (
              <i className="pi pi-check text-iron-teal text-2xl"></i>
            )
          ) : (
            <i className="pi pi-spin pi-spinner text-iron-teal text-2xl"></i>
          )}
        </div>

        <div className="loading text-lg font-semibold tracking-normal text-iron-blue mx-auto">
          Biometric security set up in progress...
        </div>
        <div className="description mx-auto">Perfect, hold that position</div>
      </div>

      {/* error dialog */}
      <>
        <Modal isOpen={isOpenError} closeModal={handleCloseErrorModal}>
          <div className="title text-xl font-bold text-red-500">Error:</div>
          <div className="error-message text-red-500">{error}</div>

          <div className="footer-action flex gap-2">
            <BasicButton
              label="Okay"
              className="bg-[#003049] text-white w-fit"
              onClick={handleOnVerifyUser}
            />
            <BasicButton
              label="No, thanks"
              className="text-[#003049] bg-white w-fit"
              onClick={handleOnCancel}
            />
          </div>
        </Modal>
      </>
    </>
  );
}
