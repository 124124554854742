import React from "react"

export default function NotFoundPage() {
  return (
    <div className="flex min-h-[800px] w-full items-center justify-center">
      <div className="relative flex h-full w-full items-center justify-center">
        <div className="flex h-[554px] w-[792px] flex-col gap-5">
          <div className="flex w-full flex-col items-center gap-3">
            <h2 className="text-[30px] font-semibold leading-[38px] text-gray-900">
              Uh-Oh! 404 Page not found
            </h2>
            <h6 className="text-14 font-normal leading-5 text-gray-900">
              The server cannot find the requested resource.
            </h6>
          </div>
        </div>
      </div>
    </div>
  )
}
