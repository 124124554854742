// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.stream-video-verify {
  object-fit: cover;
  margin-left: auto;
  margin-right: auto;
  height: 12rem;
  width: 12rem;
  border-radius: 9999px;
}

.verify-process {
  margin-top: 200px;
}`, "",{"version":3,"sources":["webpack://./src/containers/auth-requester/auth-requester.scss"],"names":[],"mappings":"AAEA;EACI,iBAAA;EACA,iBAAA;EAAA,kBAAA;EAAA,aAAA;EAAA,YAAA;EAAA,qBAAA;AADJ;;AAIA;EAEI,iBAAA;AAFJ","sourcesContent":["\n\n.stream-video-verify {\n    object-fit: cover;\n    @apply rounded-full w-48 h-48 mx-auto;\n}\n\n.verify-process{\n\n    margin-top: 200px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
