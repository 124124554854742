// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.video-progress-bar {
  background-color: rgb(0 48 73 / 0.2);
}
.video-progress-bar .p-slider-handle,
.video-progress-bar .p-slider-range {
  --tw-bg-opacity: 1;
  background-color: rgb(0 48 73 / var(--tw-bg-opacity));
}

.video-buttons {
  display: flex;
  cursor: pointer;
  justify-content: center;
  gap: 1.25rem;
}
.video-buttons .button-size {
  height: 2.5rem;
  width: 2.5rem;
}

.bar-process {
  margin-top: 1.25rem;
  border-width: 1px;
  border-bottom-width: 2px;
  --tw-border-opacity: 1;
  border-color: rgb(0 48 73 / var(--tw-border-opacity));
}

.video-frame-player {
  width: 100%;
  overflow: none;
  scrollbar-width: none;
  height: 60vh;
  padding-bottom: 5px;
}

.image-frame-container {
  width: 100%;
  height: 80%;
}

.images-frames {
  width: 100%;
  height: 100%;
}

.ProgressSpinner {
  width: 150px;
  height: 150px;
  justify-content: center;
  align-items: center;
  display: flex;
}

/* Media query for tablet-sized screens and up */
@media (min-width: 320px) {
  .video-frame-player {
    width: 100%;
    height: 60vh;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/shares/video-frame-player/video-frame-player.scss"],"names":[],"mappings":"AACE;EAAA;AAAA;AAGE;;EAAA,kBAAA;EAAA;AAAA;;AAKF;EAAA,aAAA;EAAA,eAAA;EAAA,uBAAA;EAAA;AAAA;AAEI;EAAA,cAAA;EAAA;AAAA;;AAKJ;EAAA,mBAAA;EAAA,iBAAA;EAAA,wBAAA;EAAA,sBAAA;EAAA;AAAA;;AAGF;EACE,WAAA;EACA,cAAA;EACA,qBAAA;EACA,YAAA;EACA,mBAAA;AACF;;AACA;EACE,WAAA;EACA,WAAA;AAEF;;AACA;EACE,WAAA;EACA,YAAA;AAEF;;AACA;EACE,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA;AAEF;;AACA,gDAAA;AACA;EAEE;IACE,WAAA;IACA,YAAA;EACF;AACF","sourcesContent":[".video-progress-bar {\n  @apply bg-iron-blue/20;\n  .p-slider-handle,\n  .p-slider-range {\n    @apply bg-iron-blue;\n  }\n}\n\n.video-buttons {\n  @apply flex justify-center gap-5 cursor-pointer;\n  .button-size {\n      @apply w-10 h-10;\n  }\n}\n\n.bar-process {\n  @apply border border-b-2 border-iron-blue mt-5;\n}\n\n.video-frame-player{\n  width: 100%;  \n  overflow: none;\n  scrollbar-width: none;\n  height: 60vh;\n  padding-bottom: 5px;\n}  \n.image-frame-container{\n  width: 100%;   \n  height: 80%;\n}\n\n.images-frames{\n  width: 100%;   \n  height: 100%;  \n}\n\n.ProgressSpinner{\n  width: 150px;\n  height: 150px;\n  justify-content: center;\n  align-items: center;\n  display: flex;\n}\n\n/* Media query for tablet-sized screens and up */\n@media (min-width: 320px) { \n\n  .video-frame-player{\n    width: 100%;       \n    height: 60vh;\n  }   \n\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
