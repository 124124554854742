const service = `transactions`;
export const TRANSACTION = {
    NEW_TRANSACTION: `${service}/`,
    DETAIL: `${service}/:id`,
    RESEND: `${service}/resend`,
    MARK_DONE: `${service}/:id/mark-as-done`,
    NOTIFICATAIONS: `${service}/notifications`,
    APPROVE: `${service}/:id/approve`,
    REJECT: `${service}/:id/reject`,
    FIND_TOKEN: `${service}/find-by-token`,
    GET_SMS: `${service}/:id/sms`,
    GET_SESSION_ID: `${service}/generate-session`,
    GET_LIST_FRAME: `${service}/:id/authentic-session`,
    GET_FRAME: `${service}/:id/authentic-session/:frameId`,
}