import React, { useEffect, useState ,useContext,createContext  } from "react";
import { MainLayoutType } from "./type";
import "./layout.scss";
import { ROUTERS_PATH } from "src/routers/routers";
import { useNavigate , useLocation} from "react-router-dom";
import { ReactComponent as Logo } from "src/assets/session-lock.svg";
import lockIcon from "src/assets/lock-icon.svg";
import LogoutIcon from "src/assets/logout.svg"
import { IVAuth } from "src/services/auth/IV-auth";
import useUserStore from 'src/services/user/userstore';
import { UserModel } from "src/models/user.model";



interface LayoutContextType {
  ShowHideLogOut: () => void;
  handleLogout: () => void; 
}

const LayoutContext = createContext<LayoutContextType | undefined>(undefined);

export const useLayoutContext = () => {
  const context = useContext(LayoutContext);
  if (!context) {
    throw new Error('useLayoutContext must be used within a MainLayout');
  }
  return context;
};


export default function MainLayout(props: MainLayoutType) {
  const { children } = props;
  const navigation = useNavigate();    
  const [hideLogout, setHideLogout] = useState(false);  
  const loggedInUser = useUserStore((state) => state.user);
  const setUser = useUserStore((state) => state.setUser);  
  const clearUser = useUserStore((state) => state.clearUser);  
  const location = useLocation();
  const iv = new IVAuth();

  useEffect(() => {    
    if (loggedInUser) {
      // No need to call setUser here, as it's already set
    } else {
      // Fetch user from local storage and update the store only in the relvant pages 
      const storedUser = localStorage.getItem("user");      
      if (storedUser) {
        setUser(JSON.parse(storedUser) as UserModel);
      }
    }    
  }, [loggedInUser,hideLogout]);   

  const ShowHideLogOut = () => {
    const pageToSkip = [ROUTERS_PATH.AUTH_REQUESTER, ROUTERS_PATH.SIGN_IN];
    const route = location.pathname;    
    setHideLogout(pageToSkip.includes(route));
  };


  const handleLogout = () => {    
    localStorage.clear();                 
    clearUser();
    iv.endBio();
    setTimeout(() => {
      navigation(ROUTERS_PATH.SIGN_IN);
    }, 50);

    
  }  

  return (    
    <LayoutContext.Provider value={{ ShowHideLogOut,handleLogout }}>
      <div id="bg" className="layout-container"> 
          <div className="logo-content">
            <Logo/>
          </div>
          {loggedInUser && !hideLogout && (
            <div className="icon-logout" onClick={handleLogout}><img  src={LogoutIcon} alt="LogOut"/></div>
          )}

          <div className="content-body">
            {children}
          </div>      
          <div className="footer-item">
            <div><img src={lockIcon} className="w-[24px] h-[24px]" /></div>
            <div className="text-[12px] font-semibold">Locked by AuthenticAction</div>
        </div>          
      </div>     
    </LayoutContext.Provider>
  );
}