import React, { useEffect, useRef, useState } from "react";
import { StreamingType } from "./type";
import "./stream-video.scss";

export default function Index(props: StreamingType) {
  const { id, className, handleOnLoaded } = props;

  const videoRef = useRef<any>(null);
  const canvasRef = useRef(null);
  const [stream, setStream] = useState<any>(null);

  return (    
      <div id={id}></div>    
  );
}
