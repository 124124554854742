import { useEffect, useRef, useState } from "react";
import {
  backwardIcon,
  playIcon,
  forwardIcon,
  notVerifiedIcon,
  backward2Icon,
  play2Icon,
  pause2Icon,
} from "src/assets";
import { Slider } from "primereact/slider";
import { ProgressSpinner } from "primereact/progressspinner";
import { VideoFramePlayer } from "./type";
import "./video-frame-player.scss";
import { Tooltip } from "primereact/tooltip";

export default function Index(props: VideoFramePlayer) {
  const { images, frameRate, isCompletedRequest } = props;
  const [currentFrame, setCurrentFrame] = useState(0);
  const [currentProgress, setCurrentProgress] = useState(0);
  const [isPlaying, setIsPlaying] = useState(true);
  const [intervalObject, setIntervalObject] = useState<any>(null);
  const [frameUrls, setFrameUrls] = useState<string[]>([]);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [isCompletedUrl, setIsCompletedUrl] = useState<boolean>(false);

  useEffect(() => {
    if (isCompletedUrl) {
      setIsPlaying(true);
      setIntervalObject((prevs: any) => {
        clearInterval(prevs);
        return playVideo();
      });
    }
    if (intervalObject) {
      return () => {
        setIntervalObject((prevs: any) => {
          clearInterval(prevs);
          return null;
        });
      };
    }
  }, [isCompletedUrl]);

  useEffect(() => {
    if (isCompletedUrl) {
      setCurrentProgress((100 / (frameUrls.length - 1)) * currentFrame);
      renderImage(frameUrls[currentFrame]);
    }
  }, [currentFrame]);

  const playVideo = () => {
    return setInterval(() => {
      setCurrentFrame(
        (prevCurrentFrame) => (prevCurrentFrame + 1) % frameUrls.length
      );
    }, 1000);
  };

  useEffect(() => {
    setFrameUrls([]);
    if (isCompletedRequest) {
      const urls: string[] = [];
      images.forEach((blob: Blob) => {
        const url = URL.createObjectURL(blob);
        urls.push(url);
      });
      setFrameUrls([...frameUrls, ...urls]);
      setIsCompletedUrl(true);
    }
  }, [isCompletedRequest]);

  const renderImage = (url: string) => {
    const canvas = canvasRef.current;
    document.getElementById("video-frame")?.setAttribute("src", url);
  };

  const togglePlayPause = () => {
    if (!isPlaying) {
      setIntervalObject((prevs: any) => {
        clearInterval(prevs);
        return playVideo();
      });
    } else {
      setIntervalObject((prevs: any) => {
        clearInterval(prevs);
        return null;
      });
    }
    setIsPlaying(!isPlaying);
  };

  const resetPlayer = () => {
    setCurrentFrame(0);
    setCurrentProgress(0);
  };

  return (   
      
        <div className="video-frame-player">
          <div className="image-frame-container">
            {isCompletedUrl ? 
              (<img id="video-frame" className="images-frames" alt="video-frame" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="/>):              
              (<ProgressSpinner className="ProgressSpinner"/>)
            }        
          </div>
          <div className="process-bar py-5">
            <Slider
              value={currentProgress}
              className="w-full video-progress-bar"
            />
          </div>
          <Tooltip target="i" />
          <div className="video-buttons">
            <div className="backward" onClick={resetPlayer}>
              <img
                src={backward2Icon}
                alt="backward2Icon"
                className="button-size"
              />
            </div>
            <div className="play" onClick={togglePlayPause}>
              {!isPlaying ? (
                <img src={play2Icon} alt="play2Icon" className="button-size" />
              ) : (
                <img src={pause2Icon} alt="pause2Icon" className="button-size" />
              )}
            </div>
          </div>      
        </div>    
  );
}
