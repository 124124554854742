import React, { useEffect, useMemo, useState } from "react";
import {dataVerify,STATE_VIEW} from "./type";
import lockIcon from "src/assets/lock-icon.svg";
import {
  BasicButton,
  CameraInstruction,
  Input,
  Modal,
  StreamVideo,
} from "src/components/shares";

import { useNavigate , useLocation } from "react-router-dom";
import { ROUTERS_PATH } from "src/routers/routers";
import "./create-user.scss";
import { Form, Formik, useFormik } from "formik";
import * as Yup from "yup";
import {NewUser} from "src/models/user.model";
import { UserService } from "src/services/user/user.service";
import axios, { AxiosError } from "axios";
import useUserStore from 'src/services/user/userstore';


export default function CreateUserPage() {
  const navigate = useNavigate();  
    
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [isOpenPopupConfirm, setIsOpenPopupConfirm] = useState(false);  
 

  const [error, setError] = useState<string | null>(null);
  const [inProgress, setInProgress] = useState<boolean>(false);  
  const [sessionId, setSessionId] = useState<string>("");
  const [isOpen, setOpen] = useState(false);
  const loggedInUser = useUserStore((state) => state.user);
  const setUser = useUserStore((state) => state.setUser);    
  const validationSchema = Yup.object({
    firstname: Yup.string().required('First Name is required'),
    lastname: Yup.string().required('Last Name is required'),
    email: Yup.string().required('Email is required').matches(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/, "Invalid email format"),        
    phone: Yup.string().matches(/^\(?(\d{3})\)?[-. ]?(\d{3})[-. ]?(\d{4})$|^\+1\d{10}$/, 'Invalid phone number').required('Phone number is required'),
  });
  
  useEffect(() => {        
  }, []);


  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",      
    },
    onSubmit: (values) => handleOnSubmit(values),
  });

  
  const handleOnSubmit = async (values: any) => {    
    setInProgress(true);
    
  
    if (!loggedInUser || loggedInUser.orgUsers == undefined) {
      setError('Logged in User not found');            
      setInProgress(false);
      setIsOpenPopup(true);
      return
    }    

    const { firstname, lastname, email, phone } = values;

      
    let cleanedPhone = phone.replace(/\s/g, '');
    // Add +1 if it's not already present
    if (!phone.startsWith('+1')) {
      cleanedPhone =  '+1' + phone;
    }
  

    const NewUser: NewUser = {
      email: email,
      firstName: firstname,
      lastName: lastname,      
      cellPhone: cleanedPhone,
      status: 1,
      orgid: loggedInUser.orgUsers.orgId,
      roleid: 2, /*RoleId = 2 is always Approver*/
    };

    setTimeout(async () => {      
      
      try {                
        const userservice: UserService = new UserService();
       const response = await userservice.createUser(NewUser);
        
        if (response) {                    
          setIsOpenPopupConfirm(true);          
        }         
      } catch (error: any) {          
          if (axios.isAxiosError(error)) {            
            const e = error as AxiosError;
            console.log('Create User API Response data:', JSON.stringify(e.response?.data, null, 2));            
            const errMessage = (e.response?.data as any).message;            
            setError(errMessage || 'Failed to create user. Please try again');     
            setIsOpenPopup(true);
        }
      } finally {
        setInProgress(false);       
      }
    }, 100);
  };


  const goBack = () => {      
      setTimeout(async () => {
        navigate(ROUTERS_PATH.CREATE_REQUEST);
      }, 10);      
  };

  const handleCloseModal = () => {    
    setIsOpenPopup(false);
  };

  const handleCloseModalConfirm = () => {    
    setTimeout(async () => {  
      navigate(ROUTERS_PATH.CREATE_REQUEST);
    }, 100); 
  }


  return (
    <div className="usercreation-container">     
      <div className="page-title-actions">        
        <i className="pi pi-angle-left icon-circle-simple !w-8 !h-8 mr-2" onClick={goBack}></i>        
        <span>Add a new user</span>
      </div>
      <div className="form-usercreation-wrapper">
          <div className="form-usercreation">
          <Formik
            initialValues={{
              email: "",
              firstname: "",
              lastname: "",
              phone: "",              
            }}
            validationSchema={validationSchema}
            onSubmit={handleOnSubmit}
            validateOnChange={true}
            validateOnBlur={true}>              
                <Form >
                      <div className="form-group">                
                        <Input
                          id="firstname"
                          name="firstname"
                          type="text"
                          label="First Name"
                          placeholder="Type..."
                          border={true}
                          className="createuser-input"
                        ></Input>

                        <Input
                          id="lastname"
                          name="lastname"
                          type="text"
                          label="Last Name"
                          placeholder="Type..."
                          border={true}
                          className="createuser-input"
                        ></Input>

                        <Input
                          id="email"
                          name="email"
                          type="text"
                          label="Email"
                          placeholder="Type..."
                          border={true}
                          className="createuser-input"
                        ></Input>

                        <Input
                          id="phone"
                          name="phone"
                          type="text"
                          label="Phone Number"
                          placeholder="(123)-456-7890 or 123-456-7890"
                          border={true}
                          className="createuser-input"
                        ></Input>

                      </div>
                      <div className="flex items-center justify-center mt-[2.875rem]">
                        <BasicButton
                        label="Submit"
                        className="bg-iron-blue text-white w-[45%] text-2xl/loose tracking-normal py-[0.625rem]"
                        type="submit"
                        loading={inProgress}></BasicButton>
                    </div>              
                </Form>                             
          </Formik>       
      </div>
      </div>       

      <Modal isOpen={isOpenPopup} closeModal={handleCloseModal}>
          <div className="title text-xl font-bold text-red-500">Error:</div>
          <div className="error-message text-red-500">{error}</div>

          <div className="footer-action">
            <BasicButton
              label="Close"
              className="bg-[#003049] text-white w-fit"
              onClick={handleCloseModal}
            />
          </div>
        </Modal>


        <Modal isOpen={isOpenPopupConfirm} closeModal={handleCloseModalConfirm}>
        
          <div className="title text-xl font-bold">    
              <span>User was created successfully!</span>            
          </div>

   
          <div className="footer-action">
            <BasicButton
              label="Close"
              className="bg-[#003049] text-white w-fit"
              onClick={handleCloseModalConfirm}
            />
          </div>
        </Modal>   
    </div>
  );
}
