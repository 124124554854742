import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { lockIcon, personBg2 } from "src/assets";
import {
  BasicButton,
  CameraInstruction,
  Modal,
  StreamVideo,
} from "src/components/shares";
import "./approve-not-enroll.scss";
import { Button } from "primereact/button";

export default function ApproveNotEnrollPage() {
  const { session } = useParams();
  const [isVideoLoaded, setIsVideoLoaded] = useState<boolean>(false);
  const [isOpen, setOpen] = useState(false);

  const handleSetupBiometric = () => {
    console.log("setup");
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  return (
    <>
      <div className="page-body">
        <div className="page-title">Respond to transaction request</div>
        <div className="approval-request-content">
          <div
            className="bg-approval-request"
            style={{ backgroundImage: `url(${personBg2})` }}
          ></div>

          <div className="not-enroll-actions absolute z-[0] top-[66%]">
            <div className="actions-card">
              <div className="content">
                Before we continue, you need to enroll to biometrics, to verify
                that it’s you.
              </div>
              <br />
              <div className="actions">
                <BasicButton
                  label="Set Up Biometric Security"
                  className="setup-bio bg-iron-blue text-white w-fit text-2xl/loose tracking-normal py-[0.25rem]"
                  onClick={() => setOpen(true)}
                />
                <Link
                  to={`/approve/${session}`}
                  className="link-class"
                >
                  <Button
                    className="skip-btn py-2"
                    label="Skip, I’ll do it later"
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
        <>
          <Modal isOpen={isOpen} closeModal={handleCloseModal}>
            <div className="font-medium text-lg">
              Please contact with your administrator to set up biometric
              security.
            </div>
            <div className="footer-action">
              <BasicButton
                label="Okay"
                className="bg-[#003049] text-white w-fit"
                onClick={handleCloseModal}
              />
            </div>
          </Modal>
        </>
      </div>
    </>
  );
}
