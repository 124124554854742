import { API } from "../api-constants";
import { HttpClient } from "../common-services/httpclient.service";
import { SigninModel } from "./type";

export class SigninService extends HttpClient {
    private readonly servivePath = API.AUTH;
    constructor() {
        super()
    }

    public async signIn (data: SigninModel) {
        data.token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c";
        return await this.post(this.servivePath.LOGIN, data);
    }
}