import { AxiosResponse } from "axios";
import { API } from "../api-constants";
import { HttpClient } from "../common-services/httpclient.service";
import { CreateTransModel, TransListModel } from "./type";

export class TransactionService extends HttpClient {
  private readonly servivePath = API.TRANSACTION;
  constructor() {
    super();
  }

  public async createTrans(data: CreateTransModel) {
    return await this.post(this.servivePath.NEW_TRANSACTION, data);
  }

  public async getTrans(data: TransListModel) {
    return await this.get(this.servivePath.NEW_TRANSACTION, { params: data });
  }

  public async getTransDetail(id: number) {
    const url = this.servivePath.DETAIL.replace(":id", id.toString());
    return await this.get(url);
  }

  public async resendTrans(id: number) {
    const data = { id };
    return await this.post(this.servivePath.RESEND, data);
  }

  public async deleteTrans(id: number) {
    const url = this.servivePath.DETAIL.replace(":id", id.toString());
    return await this.delete(url);
  }

  public async markDoneTrans(id: number) {
    const url = this.servivePath.MARK_DONE.replace(":id", id.toString());
    return await this.post(url, {});
  }

  public async approveTrans(id: number, amount: number) {
    const url = this.servivePath.APPROVE.replace(":id", id.toString());
    return await this.post(url, {}, { params: { amount } });
  }

  public async rejectTrans(id: number) {
    const url = this.servivePath.REJECT.replace(":id", id.toString());
    return await this.post(url, {});
  }

  public async getTransByToken(token: string) {
    return await this.get(this.servivePath.FIND_TOKEN, { params: { token } });
  }

  public async getSMSLinkByTransid(id: number) {
    const url = this.servivePath.GET_SMS.replace(":id", id.toString());
    return await this.get(url);
  }

  public async getTransSessionId() {
    return await this.post(this.servivePath.GET_SESSION_ID, {});
  }

  public async getTransListFrameByTransId(id: number) {
    let url = this.servivePath.GET_LIST_FRAME.replace(":id", id.toString());    
    return await this.get(url);
  }

  public async getFrameByFrameId(id: number, frameId: number): Promise<Response> {
    let url = this.servivePath.GET_FRAME.replace(":id", id.toString());
    url = url.replace(":frameId", frameId.toString());
    return await this.get(url, {responseType: 'blob'});
  }

  public async getAllFrameByFrameId(
    sessionId: number,
    ids: number[]
  ): Promise<Response[]> {
    return Promise.all(ids.map((id) => this.getFrameByFrameId(sessionId, id)));
  }
}
