import { API } from "../api-constants";
import { HttpClient } from "../common-services/httpclient.service";
import { NotificationListModel } from "./type";

export class NotificationService extends HttpClient {
  private readonly servivePath = API.NOTIFICATION;
  constructor() {
    super();
  }

  public async getNotifications(data: NotificationListModel) {
    return await this.get(this.servivePath.GET_NOTIFICATION, { params: data });
  }

  public async markRead(id: number) {
    const url = this.servivePath.READ_NOTIFICATION.replace(":id", id.toString());
    return await this.post(url, {});
  }
}
