import React, { useEffect, useState } from "react";
import "./auth-session.scss";
import {
  backwardIcon,
  playIcon,
  forwardIcon,
  notVerifiedIcon,
} from "src/assets";
import { Router, useLocation } from "react-router-dom";
import axios, { AxiosError } from "axios";
import { ApiResponse } from "src/utils";
import { TransactionService } from "src/services/transaction/transaction.service";
import { BasicButton, Modal, VideoFramePlayer } from "src/components/shares";
import { TransactionStatus } from "src/components/constants/transaction-status";
import { IronVestService } from "src/services/ironvest/ironvest.service";
import { personBg2 } from "src/assets";
import { Chip } from "primereact/chip";
import { useNavigate } from "react-router-dom";
import { ROUTERS_PATH } from "src/routers/routers";
import { number } from "yup";

export default function Index() {

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const transId = searchParams.get("transId");

  const [approver, setApprover] = useState<string>("");
  const [care, setCare] = useState<any>(null);
  const [transStatus, setTransStatus] = useState<string>("");
  const [enrollButtonLabel, setEnrollButtonLabel] = useState<string>("");
  const [inProgress, setInProgress] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isShowedEnroll, setIsShowedEnroll] = useState<boolean>(true);
  const [imageBlobs, setImageBlobs] = useState<Blob[]>([]);
  const [isCompletedRequest, setIsCompletedRequest] = useState<boolean>(false);
  const [isApprover, setIsApprover] = useState<boolean>(false);
  const [isOpenError, setOpenError] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const isApprover = localStorage.getItem("isApprover") == 'true' ? true: false;
    setIsApprover(isApprover);    
    handleGetTransactionDetail();    
    getVideoTransactionFrame(); 
    
  }, []);


  const goBack = () => {    
    if (isApprover)
      window.history.back();      
    else
      navigate(ROUTERS_PATH.DASHBOARD)    
    
  };


  const mockupFrames = async () => {
    const blobs: Blob[] = [];
    for (let i = 0; i < 20; i++) {
      const response = await fetch("https://picsum.photos/200/300");
      blobs.push(await response.blob());
    }
    setImageBlobs(blobs);
    setIsCompletedRequest(true);
  };

  const handleGetTransactionDetail = async () => {
    if (!transId) return;
    try {
      const transactionService: TransactionService = new TransactionService();
      const response = await transactionService.getTransDetail(Number(transId));
      if (response) {
        const { care, transactionStatus } = response;
        if (care) {
          setApprover(care.fullName);
          setCare(care);
        }

        if (transactionStatus) {
          setTransStatus(transactionStatus.name);
          const label =
            transactionStatus.name == TransactionStatus.APPROVE_NOT_ENROLLED
              ? `Enroll ${care.fullName} To Biometrics`
              : `Verify ${care.fullName}'s Approval`;
          setEnrollButtonLabel(label);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleOnEnrollVerify = async () => {
    setOpenError(false);
    setInProgress(true);
    try {
      const ironVestService: IronVestService = new IronVestService();
      const email = care.email ?? "";
      const response = await ironVestService.enrollTransaction(
        Number(transId),
        email
      );
      if (response) {
        const { status } = response;
        if (status == "success") {
          setIsOpen(true);
          setIsShowedEnroll(false);
        } else {
          setOpenError(true);
        }
      }
      console.log("Enroll/ Verify");
    } catch (error) {
      console.error(error);
      setOpenError(true);
    } finally {
      setInProgress(false);
    }
  };

  const getVideoTransactionFrame = async () => {
    try {
      const transactionService: TransactionService = new TransactionService();            
      const response = await transactionService.getTransListFrameByTransId(Number(transId));

      if (response) {
        const frameList = response.map((frame: any) => frame.id);
        const frameResponse = await transactionService.getAllFrameByFrameId(
          Number(transId),
          frameList
        );
        const blobs: Blob[] = [];
        
        frameResponse.forEach((response: any) => {
          blobs.push(response);
        });

        if (frameResponse.length > 0) {
          setImageBlobs((prevs) => [...prevs, ...blobs]);
          setIsCompletedRequest(true);
        } else {
          mockupFrames();
        }
      } else {
        mockupFrames();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const handleCloseErrorModal = () => {
    setOpenError(false);
  };

  return (
    <div className="auth-session-content-body ">
      <div className="page-title-actions">        
        <i className="pi pi-angle-left icon-circle-simple !w-8 !h-8 mr-2" onClick={goBack}></i>        
        <span>Authentic Session #{transId}</span>
      </div>
      <div className="video-player-warpper">          
        <VideoFramePlayer
          frameRate={1}
          images={imageBlobs}
          key={transId}
          isCompletedRequest={isCompletedRequest}></VideoFramePlayer>          
      </div>
      {!isApprover && isShowedEnroll &&
        (transStatus == TransactionStatus.APPROVE_NOT_ENROLLED ||
          transStatus == TransactionStatus.APPROVE_NOT_VERIFIED) && (
          <div className="action-buttons-container">
            <BasicButton
              label={enrollButtonLabel}
              className="action-buttons"
              loading={inProgress}
              onClick={handleOnEnrollVerify}
            />
          </div>
        )}      
      {/* enroll dialog */}
      <>
        <Modal isOpen={isOpen} closeModal={handleCloseModal}>
          <div className="title font-bold text-iron-blue">
            <span>You Just Verified {approver}’s Identity!</span>
          </div>
          <p>{approver} will be notified.</p>

          <div className="arpprove-image w-full py-4 relative">
            <div
              className="bg-approval w-32 h-32 rounded-full object-cover m-auto"
              style={{
                backgroundImage: `url(${personBg2})`,
                backgroundPosition: "87% 10%",
                backgroundSize: "139%",
              }}
            ></div>

            <div className="verify absolute w-full text-center bottom-[0.025rem]">
              <Chip
                className="px-2 py-[2px] bg-gradient-to-r from-[#019E86] to-[#00D2B2] w-fit"
                template={
                  <>
                    <img
                      className="h-6 w-6 ml-0 mr-1"
                      src={notVerifiedIcon}
                      alt="not-verified"
                    />
                    <span className="font-semibold text-base text-white">
                      Verified!
                    </span>
                  </>
                }
              />
            </div>
          </div>

          <div className="footer-action gap-2">
            <BasicButton
              label="Okay"
              className="bg-[#003049] text-white w-fit"
              onClick={() => setIsOpen(false)}
            />
            <BasicButton
              label="Undo"
              className="text-[#003049] bg-white w-fit"
              onClick={() => setIsOpen(false)}
            />
          </div>
        </Modal>
      </>

      {/* error dialog */}
      <>
        <Modal isOpen={isOpenError} closeModal={handleCloseErrorModal}>
          <div className="title text-xl font-bold text-red-500">Error:</div>
          <div className="error-message text-red-500">{`Cannot Enroll/ Verify. Do you want to retry?`}</div>

          <div className="footer-action flex gap-2">
            <BasicButton
              label="Retry again"
              className="bg-[#003049] text-white w-fit"
              onClick={handleOnEnrollVerify}
            />
            <BasicButton
              label="Cancel"
              className="text-[#003049] bg-white w-fit"
              onClick={() => setOpenError(false)}
            />
          </div>
        </Modal>
      </>
    </div>
  );
}
