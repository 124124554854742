import { Ironvest } from "src/ironvestsdk";
import { IronVestOption, BiometricOptionType } from "./type";
import { PermissionIVEnum } from "./permission.enum";

// import IronClient from "src/ironvestsdk/ironvest-authentic-action-sdk-js-test-2024-06-1";

class IVAuth {
  private _Ironvest: any = Ironvest;
  ironVestOption: IronVestOption;
  static ironVest: any;

  constructor(csid?: string, uid?: string) {
    this.ironVestOption = {
      baseURL: process.env.REACT_APP_COLLECT_BASE_URL ?? "https://collector.dev.statsd.io",
      cid: process.env.REACT_APP_CID ?? "session-lock-poc1",
      csid: csid ?? localStorage.getItem("token") ?? "initial",
      uid: uid ?? localStorage.getItem("email") ?? "initial",
      debug: process.env.REACT_APP_ENVIRONMENT == "development", // debug available in development only
    };    
  }

  init() {
    if (IVAuth.ironVest) return;
    try {
      console.log("new iv");
      IVAuth.ironVest = new this._Ironvest(this.ironVestOption);
    } catch (error) {
      console.error(error);
    }
  }

  update(csid: any, uid: any) {
    if (!IVAuth.ironVest) return;
    IVAuth.ironVest.setCsid(csid);
    IVAuth.ironVest.setUid(uid);
  }

  updateCSID(csid: any) {
    if (!IVAuth.ironVest) return;
    IVAuth.ironVest.setCsid(csid);
  }

  startEnrollment(elementRenderId: string) {
    if (!IVAuth.ironVest) return;
    IVAuth.ironVest.startEnrollment(
      elementRenderId,
      () => {
        // Success callback
      },
      () => {
        // Error Callback
      }
    );
  }

  startBio(optionRender: BiometricOptionType, elementRenderId: string) {
    if (!IVAuth.ironVest) return;
    IVAuth.ironVest.startBiometricSession(elementRenderId, optionRender);
  }

  endBio() {
    if (!IVAuth.ironVest) return;
    IVAuth.ironVest.stopBiometric();
  }

  requestPermission(permission: PermissionIVEnum = PermissionIVEnum.CAMERA) {
    if (!IVAuth.ironVest) return;
    IVAuth.ironVest.requestPermission(permission);
  }

  async getPermissionsStatus() {
    if (!IVAuth.ironVest) return;
    return await IVAuth.ironVest.getPermissionsStatus();
  }

}

export { IVAuth };
