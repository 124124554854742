// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title-session {
  display: flex;
  width: 100%;
}
.title-session .title {
  width: 100%;
  text-align: center;
  font-size: 1.5rem;
  line-height: 2rem;
}

.icon-circle-simple {
  height: 2.5rem;
  width: 3rem;
  cursor: pointer;
  align-content: center;
  border-radius: 9999px;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(0 48 73 / var(--tw-border-opacity));
  background-color: transparent;
  text-align: center;
  font-size: 1.25rem;
  line-height: 1.75rem;
  --tw-text-opacity: 1;
  color: rgb(0 48 73 / var(--tw-text-opacity));
}

.auth-session-content-body {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  position: relative;
  height: 90%;
}

.video-player-warpper {
  position: relative;
  overflow: auto;
  scrollbar-width: none;
  max-height: 80%;
}

.action-buttons-container {
  padding-top: 10px;
  position: relative;
  bottom: 0;
}

.action-buttons {
  text-wrap: nowrap;
  width: 100%;
  justify-content: center;
  position: relative;
  text-align: center;
  background-color: #003049;
  color: white;
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  bottom: 10px;
  font-size: 18px;
}`, "",{"version":3,"sources":["webpack://./src/containers/auth-session/auth-session.scss"],"names":[],"mappings":"AACI;EAAA,aAAA;EAAA;AAAA;AAEI;EAAA,WAAA;EAAA,kBAAA;EAAA,iBAAA;EAAA;AAAA;;AAKJ;EAAA,cAAA;EAAA,WAAA;EAAA,eAAA;EAAA,qBAAA;EAAA,qBAAA;EAAA,iBAAA;EAAA,sBAAA;EAAA,qDAAA;EAAA,6BAAA;EAAA,kBAAA;EAAA,kBAAA;EAAA,oBAAA;EAAA,oBAAA;EAAA;AAAA;;AAIA;EAAA,gBAAA;EAAA,aAAA;EAAA,sBAAA;EAAA,YAAA;EAAA,oBAAA;EAAA,qBAAA;EACA,kBAAA;EACA;AAFA;;AAKJ;EACI,kBAAA;EACA,cAAA;EACA,qBAAA;EACA,eAAA;AACJ;;AACA;EACI,iBAAA;EACA,kBAAA;EACA,SAAA;AAEJ;;AAAA;EACI,iBAAA;EACA,WAAA;EACA,uBAAA;EACA,kBAAA;EACA,kBAAA;EACA,yBAAA;EACA,YAAA;EACA,mBAAA;EACA,gBAAA;EACA,mBAAA;EACA,YAAA;EACA,eAAA;AAGJ","sourcesContent":[".title-session {\n    @apply flex w-full;\n    .title {\n        @apply w-full text-center text-2xl;\n    }\n}\n\n.icon-circle-simple {\n    @apply bg-transparent text-iron-blue rounded-full border border-iron-blue w-12 h-10 cursor-pointer content-center text-center text-xl;\n}\n\n.auth-session-content-body{\n    @apply h-full flex flex-col gap-1 mt-4 px-2;\n    position: relative;\n    height: 90%;\n}\n\n.video-player-warpper{\n    position: relative;    \n    overflow: auto;        \n    scrollbar-width: none;  \n    max-height: 80%;      \n}\n.action-buttons-container{\n    padding-top: 10px;\n    position: relative;\n    bottom: 0;\n}\n.action-buttons{\n    text-wrap: nowrap;\n    width: 100%;\n    justify-content: center;\n    position: relative;    \n    text-align: center;    \n    background-color:#003049;\n    color: white;\n    border-radius: 10px;     \n    margin-top: 10px;    \n    margin-bottom: 10px;\n    bottom: 10px;\n    font-size: 18px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
