import React, { useEffect, useRef, useState } from "react";
import {
  locationIcon,
  calendarIcon,
  accountIcon,
  notVerifiedIcon,
} from "src/assets";
import { Link } from "react-router-dom";
import "./transaction-item.scss";
import { TransactionType } from "./type";
import { TransactionStatus } from "src/components/constants/transaction-status";
import { Button } from "primereact/button";
import { redoIcon } from "src/assets";
import { Chip } from "primereact/chip";
import { Tooltip } from "primereact/tooltip";

export default function Index(props: TransactionType) {
  const {
    id,
    status,
    statusDisplay,
    amount,
    requestData,
    isSimple,
    className,
    markDoneFn,
    resendFn,
    deleteFn,
    copyFn,
    isApprover,
  } = props;
  // const statusUpper = status.toUpperCase();

  const targetRef = useRef(null);
  const [isFullyVisible, setIsFullyVisible] = useState(false);
  const [loadingResend, setLoadingResend] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [loadingCopy, setLoadingCopy] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Check if the element is fully visible
        setIsFullyVisible(
          entry.isIntersecting && entry.intersectionRatio === 1
        );
      },
      {
        threshold: [0, 1], // Trigger callback at 0 and 1 intersection ratio
      }
    );

    if (targetRef.current) {
      observer.observe(targetRef.current);
    }

    return () => {
      if (targetRef.current) {
        observer.unobserve(targetRef.current);
      }
    };
  }, []);

  const handleOnResend = () => {
    setLoadingResend(true);
    resendFn(id);
    setTimeout(() => {
      setLoadingResend(false);
    }, 500);
  };

  const handleOnDelete = () => {
    setLoadingDelete(true);
    deleteFn(id);
    setTimeout(() => {
      setLoadingDelete(false);
    }, 500);
  };

  const handleOnMarkDone = () => {
    markDoneFn(id);
  };

  const handleOnCopy = () => {
    setLoadingCopy(true);
    copyFn(id);
    setTimeout(() => {
      setLoadingCopy(false);
    }, 200);
  };

  return (
    <div
      ref={targetRef}
      style={{ opacity: isFullyVisible ? 1 : 0.6 }}
      className={"transaction-item " + (className ?? "")}
    >
      <Tooltip target=".ellipsis-behavior" />
      {isApprover &&
        (status.includes(TransactionStatus.APPROVED) ||
          status == TransactionStatus.REJECTED) && (
          <div
            className={
              status.includes(TransactionStatus.APPROVED)
                ? `approval-msg approved`
                : `approval-msg rejected`
            }
          >
            {status.includes(TransactionStatus.APPROVED) ? (
              <i className="pi pi-check"></i>
            ) : (
              <i className="pi pi-times"></i>
            )}{" "}
            You{" "}
            {status.includes(TransactionStatus.APPROVED)
              ? "Approved"
              : "Rejected"}{" "}
            this transaction
          </div>
        )}

      {(!isApprover ||
        status == "Done" ||
        status == TransactionStatus.PENDING) && (
        <div className={"status " + status.replaceAll(" ", "_")}>
          {statusDisplay}
        </div>
      )}

      <div className="amount-n-action">
        <div className="request-amount">${amount}</div>
        {!isSimple && !isApprover && (
          <div className="actions">
            {status == TransactionStatus.PENDING && (
              <>
                <Button
                  className="w-6"
                  text
                  severity="secondary"
                  aria-label="Resend"
                  loading={loadingResend}
                  onClick={handleOnResend}
                  tooltip="Resend"
                  tooltipOptions={{ showDelay: 600, hideDelay: 200 }}
                >
                  {!loadingResend && <img src={redoIcon} alt="redo-icon" />}
                </Button>
                <Button
                  className="w-6"
                  icon="pi pi-clone"
                  text
                  severity="secondary"
                  aria-label="Copy"
                  loading={loadingCopy}
                  onClick={handleOnCopy}
                  tooltip="Copy"
                  tooltipOptions={{ showDelay: 600, hideDelay: 200 }}
                />
              </>
            )}
            <Button
              className="w-6"
              icon="pi pi-trash"
              text
              severity="secondary"
              aria-label="Delete"
              loading={loadingDelete}
              onClick={handleOnDelete}
              tooltip="Delete"
              tooltipOptions={{ showDelay: 600, hideDelay: 200 }}
            />
          </div>
        )}
      </div>
      <div className="request-info">
        <div className="info to-party">
          <i className="pi pi-send"></i>
          <span>To</span>
          <span
            className="font-bold ellipsis-behavior"
            data-pr-tooltip={requestData?.to}
          >
            {requestData?.to}
          </span>
        </div>
        {((!isSimple && !isApprover) ||
          (!isSimple && isApprover && status == "Done")) && (
          <div className="info reason">
            <img
              src={locationIcon}
              alt="locationIcon"
              className="w-[24px] h-[24px]"
            />
            <span>For</span>
            <span
              className="font-bold ellipsis-behavior"
              data-pr-tooltip={requestData?.service}
            >
              {requestData?.service}
            </span>
          </div>
        )}
        {((!isSimple && !isApprover) || (!isSimple && isApprover)) && (
          <div className="info date">
            <img
              src={calendarIcon}
              alt="calendarIcon"
              className="w-[24px] h-[24px]"
            />
            <span>Date</span>
            <span className="font-bold">{requestData?.dateTime}</span>
          </div>
        )}
        {(!isApprover || (isApprover && status == "Done")) && (
          <div className="info approver">
            <img
              src={accountIcon}
              alt="accountIcon"
              className="w-[24px] h-[24px]"
            />
            {status == TransactionStatus.PENDING ?  
              (<span className="w-fit whitespace-nowrap">Waiting for</span>):
              status == TransactionStatus.APPROVE_NOT_ENROLLED ? (<span className="w-fit whitespace-nowrap">Approved by</span>):              
              status == TransactionStatus.APPROVE_NOT_VERIFIED ? (<span className="w-fit whitespace-nowrap">Approved by</span>):
              status == TransactionStatus.APPROVED ? (<span className="w-fit whitespace-nowrap">Approved by</span>):
              status == TransactionStatus.REJECTED ? (<span className="w-fit whitespace-nowrap">Rejected by</span>):
              (<span className="w-fit whitespace-nowrap">Done</span>)            }
            
            {isSimple ? (
              <>
                <Tooltip className="z-[101]" target=".approver" />
                <span
                  className="approver font-bold ellipsis-behavior w-fit"
                  data-pr-tooltip={requestData?.approver}
                  data-pr-position="top"
                >
                  {requestData?.approver}
                </span>
              </>
            ) : status == TransactionStatus.PENDING ? (
              <>
                <span
                  className="text-orange-dark-300 font-semibold ellipsis-behavior"
                  data-pr-tooltip={requestData?.approver}
                >{`Waiting on ${requestData?.approver}`}</span>
              </>
            ) : (
              <>
                <span
                  className="approver font-bold w-fit ellipsis-behavior"
                  data-pr-tooltip={requestData?.approver}
                >
                  {requestData?.approver}
                </span>
              </>
            )}

            {isSimple && status == TransactionStatus.APPROVE_NOT_ENROLLED && (
              <Chip
                className="px-2 py-[2px] bg-gradient-to-r from-[#EF520E] to-[#F61111] w-[100%]"
                template={
                  <>
                    <img
                      className="h-[18px] w-[18px] ml-0 mr-1"
                      src={notVerifiedIcon}
                      alt="not-verified"
                    />
                    <span className="font-semibold text-xs text-white whitespace-nowrap">
                      Not Verified
                    </span>
                  </>
                }
              />
            )}
          </div>
        )}
      </div>

      <div className="transaction-action">
        {status.includes(TransactionStatus.APPROVED) && !isApprover && (
          <Link
            to={`/authsession?transId=${requestData?.session}`}
            className="link-class"
          >
            <Button
              className="view-session"
              label="See Authentic Session"
            ></Button>
          </Link>
        )}

        {!isSimple &&
          !isApprover &&
          status != "Done" &&
          status != TransactionStatus.PENDING && (
            <Button
              className="mark-done"
              label="Mark Done"
              onClick={handleOnMarkDone}
            ></Button>
          )}
      </div>
    </div>
  );
}
