export const ROUTERS_PATH = {
  ROOT_PATH: "/",
  SIGN_IN: "/signin",
  DASHBOARD: "/dashboard",
  NOT_FOUND: "/notfound",
  APPROVE_REQUEST: "/approve",
  AUTH_SESSION: "/authsession",
  CREATE_REQUEST: "/createrequest",
  APPROVE_NOT_ENROLL: "/approveenrolled",
  AUTH_APPROVER: "/authapprover",
  AUTH_REQUESTER: "/authrequester",
  CREATE_USER: "/create-user",
}
