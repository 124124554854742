import React, { useRef, useEffect } from "react";
import { StreamingScreenCaptureType } from "./type";

export default function Index(props: StreamingScreenCaptureType) {
  const { id, className } = props;
  const videoRef = useRef<any>(null);
  const canvasRef = useRef(null);

  useEffect(() => {
    const constraints:DisplayMediaStreamOptions = {
      video: true,
    };

    navigator.mediaDevices
      .getDisplayMedia(constraints)
      .then((stream) => {
        videoRef.current.srcObject = stream;
      })
      .catch((err) => {
        console.error("Error accessing media devices.", err);
      });

    return () => {
      // Clean up: stop video stream
      if (videoRef.current.srcObject) {
        const stream = videoRef.current.srcObject;
        const tracks = stream.getTracks();

        tracks.forEach((track: any) => {
          track.stop();
        });
      }
    };
  }, []);

  useEffect(() => {
    const video: any = videoRef.current;
    const canvas: any = canvasRef.current;
    const ctx = canvas.getContext("2d");

    const drawFrame = () => {
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
      requestAnimationFrame(drawFrame);
    };

    video.addEventListener("play", drawFrame);

    return () => {
      video.removeEventListener("play", drawFrame);
    };
  }, []);

  useEffect(() => {
    const constraints = {
      video: true,
    };

    navigator.mediaDevices
      .getUserMedia(constraints)
      .then((stream) => {
        videoRef.current.srcObject = stream;
      })
      .catch((err) => {
        console.error("Error accessing media devices.", err);
      });

    return () => {
      // Clean up: stop video stream
      if (videoRef.current.srcObject) {
        const stream = videoRef.current.srcObject;
        const tracks = stream.getTracks();

        tracks.forEach((track: any) => {
          track.stop();
        });
      }
    };
  }, []);

  useEffect(() => {
    const video: any = videoRef.current;
    const canvas: any = canvasRef.current;
    const ctx = canvas.getContext("2d");

    const drawFrame = () => {
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
      requestAnimationFrame(drawFrame);
    };

    video.addEventListener("play", drawFrame);

    return () => {
      video.removeEventListener("play", drawFrame);
    };
  }, []);

  return (
    <>
      <video ref={videoRef} autoPlay style={{ display: 'none' }}></video>
      <canvas
        id={id}
        className={className}
        ref={canvasRef}
      ></canvas>
    </>
  );
}
