export const STATE_VIEW = {
  CLOSE: "CLOSE",
  SELECT: "SELECT",
  NEW: "NEW",
};
export const dataVerify = [
  {
    name: "CEO",
    email: "ceo@gmail.com",
    phone: "845546",
  },
  {
    name: "VP Product",
    email: "vp-product@gmail.com",
    phone: "845546",
  },
];
export interface ApproverType {
  id?: number;
  firstName: string;
  lastName: string;
  email: string;
  status: number;
  cellPhone: string;
  orgUsers?: any;
}
