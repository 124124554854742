// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#ironvest_videoLoginPage canvas.AuthenticAction-canvas {
  width: 200px !important;
  height: 200px !important;
  margin-top: 5.5rem !important;
  margin-left: 25% !important;
  border-radius: 100px !important;
}`, "",{"version":3,"sources":["webpack://./src/components/shares/stream-video/stream-video.scss"],"names":[],"mappings":"AAAA;EACI,uBAAA;EACA,wBAAA;EACA,6BAAA;EACA,2BAAA;EACA,+BAAA;AACJ","sourcesContent":["#ironvest_videoLoginPage canvas.AuthenticAction-canvas {\n    width: 200px!important;\n    height: 200px!important;\n    margin-top: 5.5rem!important;\n    margin-left: 25%!important;\n    border-radius: 100px!important;    \n  }\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
