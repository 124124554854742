export const transactions: any = [
    {
        id: 4,
        session: "1234",
        status: "Pending",
        amount: 600,
        to: "Google Inc",
        service: "Cloud Platform",
        dateTime: "June 28 2024, 16:00",
        approver: "Finn"
    },
    {
        id: 3,
        session: "5678",
        status: "Done",
        amount: 1000,
        to: "AWS Inc",
        service: "Cloud Services",
        dateTime: "June 28 2024, 15:00",
        approver: "Avi"
    },
    {
        id: 2,
        session: "15648",
        status: "Approved",
        amount: 200,
        to: "AHA Computer Inc",
        service: "Office tables",
        dateTime: "June 28 2024, 14:00",
        approver: "Ted"
    },
    {
        id: 1,
        session: "94685",
        status: "Pending",
        amount: 600,
        to: "Google Inc",
        service: "Certificates",
        dateTime: "June 28 2024, 13:00",
        approver: "Nissim"
    },
]

export const notifications: any = [
    {
        id: 4,
        session: "1234",
        status: "Rejected",
        amount: 600,
        to: "Google Inc",
        approver: "Finn"
    },
    {
        id: 3,
        session: "5678",
        status: "Done",
        amount: 1000,
        to: "AWS Inc",
        approver: "Avi"
    },
    {
        id: 1,
        session: "94685",
        status: "Approved",
        amount: 600,
        to: "Google Inc",
        approver: "Nissim"
    },
]