/*import { create } from 'zustand';
import { UserModel } from 'src/models/user.model';

  
  interface UserStore {
    user: UserModel | null;
    setUser: (userData: UserModel) => void;
    clearUser: () => void;
  }


  const useUserStore = create<UserStore>((set) => ({
    user: null,
    setUser: (userData: UserModel) => set({ user: userData }),
    clearUser: () => set({ user: null }),    
  }));
  
  export default useUserStore;

*/
import {create} from 'zustand'
import { persist ,createJSONStorage  } from 'zustand/middleware'
import { UserModel } from "src/models/user.model"

interface UserState {
  user: UserModel | null;
  setUser: (user: UserModel) => void;
  clearUser: () => void;
}

const useUserStore = create(
  persist<UserState>(
    (set) => ({
      user: null,
      setUser: (user) => set({ user }),
      clearUser: () => set({ user: null }),  
    }),
    {
      name: 'sl-user-storage', // unique name for the storage      
      storage: createJSONStorage(() => localStorage), // use localStorage as the storage mechanism
    }
  )
)

export default useUserStore;
